import axios from 'axios';

const baseUrl = "https://api.qorbiworld.com"
const devUrl = "https://dev-api.qorbiworld.com"
// const baseUrl = "http://localhost:4001"

export const getQorbiUser = async (identifier) => {
    const user = await axios.get(`${baseUrl}/sphere-one/user/passport/${identifier}`)
    return user.data;
};

export const getQorbiUserSafe = async (identifier) => {
    const user = await axios.get(`${baseUrl}/sphere-one/user/passport-a954aa/${identifier}`)
    return user.data;
};

export const createQorbiUser = async (userData) => {
    const response = await axios.post(`${baseUrl}/sphere-one/add-user`, userData)
    return response;
};

export const updateQorbiUser = async (userData) => {
    const response = await axios.patch(`${baseUrl}/sphere-one/update-user`, userData);
    return response;
};

export const getOpenLootUserData = async (code, userId, redirectUri) => {
    console.log();
    const response = await axios.post(`${baseUrl}/open-loot/get-user-data`, {
        code,
        redirectUri,
        userId,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    });
    return response.data;
};

export const checkAndSpend = async (email, otpCode) => {
    try {
        const response = await axios.patch(`${baseUrl}/sphere-one/check-and-spend/${email}/${otpCode}`);
        console.log(response);
        return true;
    } catch (error) {
        console.error("Error whitelisting:", error);
        throw error;
    }
};

export const requestOtp = async (email) => {
    try {
        const response = await axios.post(`${baseUrl}/sphere-one/request-otp/${email}`);
        return response.data.message === "OTP sent successfully" && true
    } catch (error) {
        console.error("Error requesting OTP:", error);
        throw error;
    }
};

export const getOpenLootAssets = async (id) => {
    const assetsDev = id === 'df17dbc0-714d-49d4-b8c1-9985e51ff6c9' ? devUrl : baseUrl;
    try {
        const response = await axios.get(`${assetsDev}/open-loot/user-nfts/${id}`);
        const assets = response.data;

        for (let asset of assets) {
            if (asset.metadata?.name === "Qorb Premier Badge") {
                try {
                    const checkMintResponse = await axios.get(
                        `${baseUrl}/v1/check-badge-able-to-mint?tokenId=${asset.id}`
                    );
                    asset.isAble = checkMintResponse.data.isAble === true;
                } catch (checkError) {
                    console.error(`Error checking if badge can mint for asset ID ${asset.id}:`, checkError);
                    asset.isAble = false;
                }
            } else {
                asset.isAble = false; // Default to false if not a "Qorb Premier Badge"
            }
        }

        return assets;
    } catch (error) {
        console.error("Error fetching Open Loot assets:", error);
        throw error;
    }
};