/* eslint-disable no-restricted-globals */
import axios from "axios";
const location = window.location
const url = location.host === 'dev.qorbaby.com' || location.host === 'localhost:3000' || location.host === '127.0.0.1:8887' ? "https://dev-api.qorbaby.com/v1/" : "https://api.qorbaby.com/v1/";
const instance = axios.create({
    baseURL: url
})
instance.interceptors.request.use(
  async (config) => {
    let token = await localStorage.getItem('token');
    if(config.url!=='auth/refresh-tokens'){
      if (token) {
        config.headers.Authorization = token;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// this is an interceptor once the request return the response, if something is wrong we can catch here before the request go to the catch error
instance.interceptors.response.use(
  (res)=>{
    return res
  },
  async (err) => {
    const originalConfig=err.config
    if(err.response){
      // token expire
      if(err.response.status===401 && !originalConfig._retry){
        originalConfig._retry=true
        try {
          const refresh= await refreshToken()
          await localStorage.setItem('token','Bearer '+refresh.data.access.token)
          await localStorage.setItem('refreshtoken',refresh.data.refresh.token)
          instance.defaults.headers.common['Authorization']=`Bearer ${refresh.data.access.token}`
          return instance(originalConfig)
        } catch (error) {
          if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
          }
        }
      }
    }
    return Promise.reject(err);
  }
)
const refreshToken=async()=>{
  const token=await localStorage.getItem('refreshtoken')
  return instance.post('users/auth/refreshToken',{refreshToken:token})
}
export const postData = async (endpoint, params) => {
  try {
    let respond = await instance.post(endpoint, params)
    return { success: true, data: respond.data }
  } catch (error) {
    return { success: false, data: error.response.data }
  }
}
export const getData = async (endpoint) => {

  try {
    let respond =await instance.get(endpoint)
    return { success: true, data: respond.data }
  } catch (error) {
    return { success: false, data: error.response.data }
  }
}
export const putData = async (endpoint, data) => {
  try {
    let respond = await instance.put(endpoint, data)
    return { success: true, data: respond.data }
  } catch (error) {
    return { success: false, data: error.response.data }
  }
}
export const pathData = async (endpoint, data) => {
    try {
      let respond = await instance.patch(endpoint, data)
      return { success: true, data: respond.data }
    } catch (error) {
      return { success: false, data: error.response.data }
    }
  }
export const deleteData=async(endpoint)=>{
  try{
    let respond = await instance.delete(endpoint)
    return { success: true, data: respond.data }
  }catch(error){
    return { success: false, data: error.response.data }
  }
} 

