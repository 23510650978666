import React, { useState, useContext, useEffect } from 'react';
import ErrorShowing from './ErrorShowing';
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import { Context } from "../context/WalletContext";

const WalletSelectionOverlay = () => {

    const { state, LoginWalletSphereone, LoginWallet, } = useContext(Context);
    const { sphereOneUser, multipleWallets, setMultipleWallets, devicedUsed } = useSphereOneProvider();
    const [selectedWallet, setSelectedWallet] = useState(state.userWallet.wallets.filter(wallet => wallet.address === state.userWallet.string)[0]);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleWalletSelection = async () => {
        if (selectedWallet) {
            if (selectedWallet.readOnly && selectedWallet.isImported && devicedUsed !== 'mobile') {
                try {
                    LoginWallet();
                    LoginWalletSphereone(selectedWallet, state.userWallet.wallets)
                    setMultipleWallets(false)
                } catch (error) {
                    console.error(error);
                }
            } else {
                LoginWalletSphereone(selectedWallet, state.userWallet.wallets)
                setMultipleWallets(false)
            }
        } else {
            setErrorMsg('Please select a wallet.');
        }
    }
    useEffect(() => {
        // console.log("Selected Wallet: ", selectedWallet.address);
    }, [selectedWallet])
    useEffect(() => {
        // console.log("New String", state.userWallet.string)
    }, [state.userWallet.string])
    if (!multipleWallets) {
        return null;
    }
    if (sphereOneUser !== null) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}
            >
                {errorMsg && <ErrorShowing errorText={errorMsg} />}
                <div
                    style={{
                        background: 'black',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    <p style={{ fontSize: '24px', color: 'white' }}>We noticed you have more than one IMX wallet</p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>Choose one to make this transaction</p>
                    <select
                        value={selectedWallet.address}
                        onChange={(e) => setSelectedWallet(state.userWallet.wallets.filter(wallet => wallet.address === e.target.value)[0])}
                        style={{
                            width: '100%',
                            padding: '10px',
                            margin: '10px 0',
                            border: '1px solid #BA246E',
                            borderRadius: '5px',
                            fontSize: '16px',
                        }}
                    >
                        <option value="" disabled>Select a Wallet</option>
                        {state.userWallet.wallets.map((wallet, index) => (
                            <option key={wallet.address} value={wallet.address}>
                                {!wallet.isImported ? "Main In-Game" : wallet.label || "Secondary"} - {wallet.address}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleWalletSelection}
                        style={{
                            backgroundColor: '#25C486',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            fontSize: '18px',
                            cursor: 'pointer',
                        }}
                    >
                        Select Wallet
                    </button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default WalletSelectionOverlay;