import * as React from "react";
const LoadingTextLootbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: "auto",
      background: "rgb(187, 37, 110)",
      display: "block",
      shapeRendering: "auto",
    }}
    width={280}
    height={40}
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <style type="text/css">
      {
        "\n  text {\n    text-anchor: middle; font-size: 16px; opacity: 0;\n  }\n"
      }
    </style>
    <g
      style={{
        transformOrigin: "140px 20px",
        transform: "scale(1)",
      }}
    >
      <g transform="translate(140,20)">
        <g transform="translate(0,0)">
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-112.41px -1.09447px",
              animation:
                "1s linear -0.638s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M0.16-9.86L0.16-11.20L8.56-11.20L8.56-9.86L5.12-9.86L5.12 0L3.60 0L3.60-9.86L0.16-9.86"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-104.3px 0.250533px",
              animation:
                "1s linear -0.616s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M14.94-8.42L14.51-6.93L14.51-6.93Q14.21-7.06 13.79-7.06L13.79-7.06L13.79-7.06Q13.34-7.06 12.92-6.86L12.92-6.86L12.92-6.86Q12.50-6.67 12.17-6.29L12.17-6.29L12.17-6.29Q11.84-5.90 11.64-5.30L11.64-5.30L11.64-5.30Q11.44-4.70 11.44-3.89L11.44-3.89L11.44 0L10 0L10-8.32L11.39-8.32L11.39-6.51L11.39-6.51Q11.54-6.90 11.78-7.26L11.78-7.26L11.78-7.26Q12.02-7.63 12.34-7.90L12.34-7.90L12.34-7.90Q12.67-8.18 13.10-8.34L13.10-8.34L13.10-8.34Q13.54-8.51 14.06-8.51L14.06-8.51L14.06-8.51Q14.30-8.51 14.54-8.49L14.54-8.49L14.54-8.49Q14.77-8.46 14.94-8.42L14.94-8.42"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-97.525px 2.10553px",
              animation:
                "1s linear -0.594s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M16.59 3.71L16.59 3.71L16.59 3.71Q16.11 3.71 15.75 3.62L15.75 3.62L15.75 3.62Q15.39 3.52 15.18 3.39L15.18 3.39L15.50 2.19L15.50 2.19Q15.70 2.30 15.98 2.38L15.98 2.38L15.98 2.38Q16.26 2.46 16.62 2.46L16.62 2.46L16.62 2.46Q16.96 2.46 17.26 2.37L17.26 2.37L17.26 2.37Q17.55 2.27 17.82 2.03L17.82 2.03L17.82 2.03Q18.10 1.79 18.34 1.38L18.34 1.38L18.34 1.38Q18.59 0.96 18.83 0.34L18.83 0.34L19.20-0.62L18.43-0.62L15.18-7.94L16.46-8.51L19.57-1.58L21.98-8.45L23.31-7.94L20.26 0.42L20.26 0.42Q19.90 1.38 19.51 2.01L19.51 2.01L19.51 2.01Q19.12 2.64 18.67 3.02L18.67 3.02L18.67 3.02Q18.22 3.41 17.70 3.56L17.70 3.56L17.70 3.56Q17.18 3.71 16.59 3.71"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-91.3px 0.345533px",
              animation:
                "1s linear -0.572s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M26.19-8.32L26.19 0L24.75 0L24.75-8.32L26.19-8.32"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-91.3px -6.24447px",
              animation:
                "1s linear -0.55s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M25.47-9.74L25.47-9.74L25.47-9.74Q25.06-9.74 24.76-10.04L24.76-10.04L24.76-10.04Q24.46-10.34 24.46-10.75L24.46-10.75L24.46-10.75Q24.46-11.22 24.74-11.49L24.74-11.49L24.74-11.49Q25.01-11.76 25.47-11.76L25.47-11.76L25.47-11.76Q25.89-11.76 26.18-11.46L26.18-11.46L26.18-11.46Q26.48-11.17 26.48-10.75L26.48-10.75L26.48-10.75Q26.48-10.29 26.21-10.02L26.21-10.02L26.21-10.02Q25.94-9.74 25.47-9.74"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-84.525px 0.250533px",
              animation:
                "1s linear -0.528s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M30.14-8.32L30.14-6.93L30.14-6.93Q30.59-7.60 31.29-8.06L31.29-8.06L31.29-8.06Q31.98-8.51 32.94-8.51L32.94-8.51L32.94-8.51Q34.35-8.51 35.05-7.66L35.05-7.66L35.05-7.66Q35.74-6.82 35.74-5.42L35.74-5.42L35.74 0L34.30 0L34.30-5.26L34.30-5.26Q34.30-6.18 33.89-6.71L33.89-6.71L33.89-6.71Q33.47-7.25 32.70-7.25L32.70-7.25L32.70-7.25Q32.34-7.25 31.98-7.11L31.98-7.11L31.98-7.11Q31.63-6.98 31.30-6.74L31.30-6.74L31.30-6.74Q30.98-6.50 30.70-6.18L30.70-6.18L30.70-6.18Q30.42-5.86 30.19-5.50L30.19-5.50L30.19 0L28.75 0L28.75-8.32L30.14-8.32"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-75.39px 2.10553px",
              animation:
                "1s linear -0.506s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M41.52-8.51L41.52-8.51L41.52-8.51Q42.24-8.51 42.81-8.27L42.81-8.27L42.81-8.27Q43.38-8.03 43.79-7.68L43.79-7.68L43.79-8.32L45.10-8.32L45.10-0.19L45.10-0.19Q45.10 0.85 44.83 1.58L44.83 1.58L44.83 1.58Q44.56 2.32 44.06 2.79L44.06 2.79L44.06 2.79Q43.55 3.26 42.85 3.49L42.85 3.49L42.85 3.49Q42.14 3.71 41.28 3.71L41.28 3.71L41.28 3.71Q40.53 3.71 39.84 3.54L39.84 3.54L39.84 3.54Q39.15 3.36 38.61 3.01L38.61 3.01L39.14 1.76L39.14 1.76Q39.52 2.06 40.03 2.26L40.03 2.26L40.03 2.26Q40.54 2.46 41.28 2.46L41.28 2.46L41.28 2.46Q42.42 2.46 43.06 1.87L43.06 1.87L43.06 1.87Q43.71 1.28 43.71-0.05L43.71-0.05L43.71-1.07L43.71-1.07Q43.39-0.69 42.79-0.34L42.79-0.34L42.79-0.34Q42.19 0 41.36 0L41.36 0L41.36 0Q40.66 0 39.98-0.28L39.98-0.28L39.98-0.28Q39.31-0.56 38.80-1.10L38.80-1.10L38.80-1.10Q38.29-1.63 37.98-2.41L37.98-2.41L37.98-2.41Q37.66-3.18 37.66-4.19L37.66-4.19L37.66-4.19Q37.66-5.12 37.97-5.91L37.97-5.91L37.97-5.91Q38.27-6.70 38.79-7.28L38.79-7.28L38.79-7.28Q39.31-7.86 40.02-8.18L40.02-8.18L40.02-8.18Q40.72-8.51 41.52-8.51zM41.68-1.28L41.68-1.28L41.68-1.28Q42.32-1.28 42.83-1.57L42.83-1.57L42.83-1.57Q43.34-1.86 43.66-2.24L43.66-2.24L43.66-6.59L43.66-6.59Q43.30-6.85 42.82-7.06L42.82-7.06L42.82-7.06Q42.34-7.26 41.66-7.26L41.66-7.26L41.66-7.26Q41.17-7.26 40.71-7.06L40.71-7.06L40.71-7.06Q40.26-6.86 39.90-6.48L39.90-6.48L39.90-6.48Q39.55-6.10 39.34-5.54L39.34-5.54L39.34-5.54Q39.14-4.99 39.14-4.27L39.14-4.27L39.14-4.27Q39.14-3.58 39.34-3.03L39.34-3.03L39.34-3.03Q39.55-2.48 39.90-2.09L39.90-2.09L39.90-2.09Q40.26-1.70 40.71-1.49L40.71-1.49L40.71-1.49Q41.17-1.28 41.68-1.28"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-62.49px -0.719467px",
              animation:
                "1s linear -0.484s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M52.74-8.02L53.04-10.64L54.14-10.64L54.14-8.02L56.45-8.02L56.45-6.77L54.14-6.77L54.14-2.75L54.14-2.75Q54.14-2.29 54.25-1.97L54.25-1.97L54.25-1.97Q54.35-1.65 54.53-1.46L54.53-1.46L54.53-1.46Q54.70-1.26 54.95-1.18L54.95-1.18L54.95-1.18Q55.20-1.09 55.47-1.09L55.47-1.09L55.47-1.09Q55.87-1.09 56.22-1.22L56.22-1.22L56.22-1.22Q56.58-1.34 56.90-1.52L56.90-1.52L57.30-0.37L57.30-0.37Q56.98-0.18 56.43 0.01L56.43 0.01L56.43 0.01Q55.89 0.19 55.30 0.19L55.30 0.19L55.30 0.19Q54.10 0.19 53.40-0.59L53.40-0.59L53.40-0.59Q52.70-1.38 52.70-2.86L52.70-2.86L52.70-6.77L51.26-6.77L51.26-8.02L52.74-8.02"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-54.85px 0.345533px",
              animation:
                "1s linear -0.462s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M64.59-4.16L64.59-4.16L64.59-4.16Q64.59-4.78 64.39-5.34L64.39-5.34L64.39-5.34Q64.19-5.90 63.83-6.32L63.83-6.32L63.83-6.32Q63.47-6.74 62.98-6.98L62.98-6.98L62.98-6.98Q62.50-7.23 61.92-7.23L61.92-7.23L61.92-7.23Q60.66-7.23 59.95-6.40L59.95-6.40L59.95-6.40Q59.25-5.57 59.25-4.19L59.25-4.19L59.25-4.19Q59.25-3.57 59.45-3L59.45-3L59.45-3Q59.65-2.43 60.01-2.01L60.01-2.01L60.01-2.01Q60.37-1.58 60.86-1.34L60.86-1.34L60.86-1.34Q61.34-1.09 61.92-1.09L61.92-1.09L61.92-1.09Q62.54-1.09 63.04-1.31L63.04-1.31L63.04-1.31Q63.54-1.54 63.88-1.94L63.88-1.94L63.88-1.94Q64.22-2.35 64.41-2.91L64.41-2.91L64.41-2.91Q64.59-3.47 64.59-4.16zM57.78-4.16L57.78-4.16L57.78-4.16Q57.78-5.17 58.10-5.98L58.10-5.98L58.10-5.98Q58.42-6.78 58.97-7.34L58.97-7.34L58.97-7.34Q59.52-7.90 60.28-8.21L60.28-8.21L60.28-8.21Q61.04-8.51 61.92-8.51L61.92-8.51L61.92-8.51Q62.78-8.51 63.54-8.21L63.54-8.21L63.54-8.21Q64.30-7.90 64.86-7.34L64.86-7.34L64.86-7.34Q65.42-6.78 65.74-5.98L65.74-5.98L65.74-5.98Q66.06-5.18 66.06-4.19L66.06-4.19L66.06-4.19Q66.06-3.20 65.75-2.39L65.75-2.39L65.75-2.39Q65.44-1.58 64.88-1.01L64.88-1.01L64.88-1.01Q64.32-0.43 63.56-0.12L63.56-0.12L63.56-0.12Q62.80 0.19 61.92 0.19L61.92 0.19L61.92 0.19Q61.02 0.19 60.26-0.13L60.26-0.13L60.26-0.13Q59.50-0.45 58.95-1.02L58.95-1.02L58.95-1.02Q58.40-1.60 58.09-2.40L58.09-2.40L58.09-2.40Q57.78-3.20 57.78-4.16"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-42.005px -1.50947px",
              animation:
                "1s linear -0.44s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M77.87-11.50L77.44-10.29L77.44-10.29Q77.10-10.46 76.72-10.61L76.72-10.61L76.72-10.61Q76.34-10.75 75.87-10.75L75.87-10.75L75.87-10.75Q75.20-10.75 74.79-10.33L74.79-10.33L74.79-10.33Q74.38-9.90 74.38-9.09L74.38-9.09L74.38-8.02L76.85-8.02L76.85-6.77L74.38-6.77L74.38 0L72.94 0L72.94-6.77L71.66-6.77L71.66-8.02L72.94-8.02L72.94-8.99L72.94-8.99Q72.94-9.70 73.15-10.26L73.15-10.26L73.15-10.26Q73.36-10.82 73.73-11.22L73.73-11.22L73.73-11.22Q74.10-11.62 74.62-11.82L74.62-11.82L74.62-11.82Q75.15-12.03 75.78-12.03L75.78-12.03L75.78-12.03Q76.45-12.03 76.94-11.88L76.94-11.88L76.94-11.88Q77.42-11.73 77.87-11.50L77.87-11.50"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-37.7px 0.345533px",
              animation:
                "1s linear -0.418s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M79.79-8.32L79.79 0L78.35 0L78.35-8.32L79.79-8.32"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-37.7px -6.24447px",
              animation:
                "1s linear -0.396s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M79.07-9.74L79.07-9.74L79.07-9.74Q78.66-9.74 78.36-10.04L78.36-10.04L78.36-10.04Q78.06-10.34 78.06-10.75L78.06-10.75L78.06-10.75Q78.06-11.22 78.34-11.49L78.34-11.49L78.34-11.49Q78.61-11.76 79.07-11.76L79.07-11.76L79.07-11.76Q79.49-11.76 79.78-11.46L79.78-11.46L79.78-11.46Q80.08-11.17 80.08-10.75L80.08-10.75L80.08-10.75Q80.08-10.29 79.81-10.02L79.81-10.02L79.81-10.02Q79.54-9.74 79.07-9.74"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-30.925px 0.250533px",
              animation:
                "1s linear -0.374s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M83.74-8.32L83.74-6.93L83.74-6.93Q84.19-7.60 84.89-8.06L84.89-8.06L84.89-8.06Q85.58-8.51 86.54-8.51L86.54-8.51L86.54-8.51Q87.95-8.51 88.65-7.66L88.65-7.66L88.65-7.66Q89.34-6.82 89.34-5.42L89.34-5.42L89.34 0L87.90 0L87.90-5.26L87.90-5.26Q87.90-6.18 87.49-6.71L87.49-6.71L87.49-6.71Q87.07-7.25 86.30-7.25L86.30-7.25L86.30-7.25Q85.94-7.25 85.58-7.11L85.58-7.11L85.58-7.11Q85.23-6.98 84.90-6.74L84.90-6.74L84.90-6.74Q84.58-6.50 84.30-6.18L84.30-6.18L84.30-6.18Q84.02-5.86 83.79-5.50L83.79-5.50L83.79 0L82.35 0L82.35-8.32L83.74-8.32"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-21.285px -1.31947px",
              animation:
                "1s linear -0.352s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M94.98 0.19L94.98 0.19L94.98 0.19Q94.21 0.19 93.53-0.11L93.53-0.11L93.53-0.11Q92.85-0.42 92.34-0.98L92.34-0.98L92.34-0.98Q91.84-1.54 91.55-2.33L91.55-2.33L91.55-2.33Q91.26-3.12 91.26-4.11L91.26-4.11L91.26-4.11Q91.26-5.06 91.56-5.86L91.56-5.86L91.56-5.86Q91.86-6.67 92.36-7.26L92.36-7.26L92.36-7.26Q92.86-7.84 93.54-8.18L93.54-8.18L93.54-8.18Q94.21-8.51 94.96-8.51L94.96-8.51L94.96-8.51Q95.79-8.51 96.39-8.18L96.39-8.18L96.39-8.18Q96.99-7.84 97.33-7.49L97.33-7.49L97.33-11.84L98.77-11.84L98.77-2.02L98.77-2.02Q98.77-1.41 99.06-1.22L99.06-1.22L99.06-1.22Q99.36-1.04 99.71-1.04L99.71-1.04L99.41 0.08L99.41 0.08Q97.89 0.08 97.55-1.14L97.55-1.14L97.55-1.14Q97.18-0.62 96.56-0.22L96.56-0.22L96.56-0.22Q95.94 0.19 94.98 0.19zM95.30-1.09L95.30-1.09L95.30-1.09Q95.89-1.09 96.42-1.37L96.42-1.37L96.42-1.37Q96.96-1.65 97.33-2.10L97.33-2.10L97.33-6.22L97.33-6.22Q97.02-6.61 96.49-6.94L96.49-6.94L96.49-6.94Q95.95-7.26 95.28-7.26L95.28-7.26L95.28-7.26Q94.70-7.26 94.23-7.02L94.23-7.02L94.23-7.02Q93.76-6.78 93.42-6.37L93.42-6.37L93.42-6.37Q93.09-5.95 92.91-5.38L92.91-5.38L92.91-5.38Q92.74-4.82 92.74-4.16L92.74-4.16L92.74-4.16Q92.74-3.49 92.93-2.93L92.93-2.93L92.93-2.93Q93.12-2.37 93.46-1.96L93.46-1.96L93.46-1.96Q93.81-1.55 94.28-1.32L94.28-1.32L94.28-1.32Q94.75-1.09 95.30-1.09"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-8.44499px -0.719467px",
              animation:
                "1s linear -0.33s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M106.78-8.02L107.09-10.64L108.19-10.64L108.19-8.02L110.50-8.02L110.50-6.77L108.19-6.77L108.19-2.75L108.19-2.75Q108.19-2.29 108.30-1.97L108.30-1.97L108.30-1.97Q108.40-1.65 108.58-1.46L108.58-1.46L108.58-1.46Q108.75-1.26 109-1.18L109-1.18L109-1.18Q109.25-1.09 109.52-1.09L109.52-1.09L109.52-1.09Q109.92-1.09 110.27-1.22L110.27-1.22L110.27-1.22Q110.62-1.34 110.94-1.52L110.94-1.52L111.34-0.37L111.34-0.37Q111.02-0.18 110.48 0.01L110.48 0.01L110.48 0.01Q109.94 0.19 109.34 0.19L109.34 0.19L109.34 0.19Q108.14 0.19 107.45-0.59L107.45-0.59L107.45-0.59Q106.75-1.38 106.75-2.86L106.75-2.86L106.75-6.77L105.31-6.77L105.31-8.02L106.78-8.02"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "-0.48999px -1.41447px",
              animation:
                "1s linear -0.308s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M114.22-11.84L114.22-6.99L114.22-6.99Q114.67-7.65 115.35-8.08L115.35-8.08L115.35-8.08Q116.03-8.51 116.98-8.51L116.98-8.51L116.98-8.51Q118.38-8.51 119.08-7.66L119.08-7.66L119.08-7.66Q119.78-6.82 119.78-5.42L119.78-5.42L119.78 0L118.34 0L118.34-5.26L118.34-5.26Q118.34-6.18 117.93-6.71L117.93-6.71L117.93-6.71Q117.52-7.25 116.75-7.25L116.75-7.25L116.75-7.25Q116.38-7.25 116.02-7.11L116.02-7.11L116.02-7.11Q115.66-6.98 115.34-6.74L115.34-6.74L115.34-6.74Q115.02-6.50 114.74-6.18L114.74-6.18L114.74-6.18Q114.45-5.86 114.22-5.50L114.22-5.50L114.22 0L112.78 0L112.78-11.84L114.22-11.84"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "8.60001px 0.345533px",
              animation:
                "1s linear -0.286s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M128.99-3.57L123.17-3.57L123.17-3.57Q123.31-2.40 123.98-1.74L123.98-1.74L123.98-1.74Q124.66-1.09 125.76-1.09L125.76-1.09L125.76-1.09Q126.48-1.09 127.06-1.24L127.06-1.24L127.06-1.24Q127.63-1.39 128.16-1.63L128.16-1.63L128.50-0.40L128.50-0.40Q127.92-0.13 127.20 0.03L127.20 0.03L127.20 0.03Q126.48 0.19 125.58 0.19L125.58 0.19L125.58 0.19Q124.69 0.19 123.97-0.10L123.97-0.10L123.97-0.10Q123.25-0.40 122.74-0.95L122.74-0.95L122.74-0.95Q122.24-1.50 121.97-2.31L121.97-2.31L121.97-2.31Q121.70-3.12 121.70-4.16L121.70-4.16L121.70-4.16Q121.70-5.09 121.97-5.89L121.97-5.89L121.97-5.89Q122.24-6.69 122.74-7.27L122.74-7.27L122.74-7.27Q123.23-7.86 123.92-8.18L123.92-8.18L123.92-8.18Q124.61-8.51 125.46-8.51L125.46-8.51L125.46-8.51Q126.35-8.51 127.02-8.21L127.02-8.21L127.02-8.21Q127.70-7.90 128.14-7.38L128.14-7.38L128.14-7.38Q128.59-6.85 128.82-6.14L128.82-6.14L128.82-6.14Q129.04-5.44 129.04-4.66L129.04-4.66L129.04-4.66Q129.04-4.10 128.99-3.57L128.99-3.57zM125.42-7.26L125.42-7.26L125.42-7.26Q124.48-7.26 123.88-6.62L123.88-6.62L123.88-6.62Q123.28-5.98 123.15-4.74L123.15-4.74L127.71-4.74L127.71-4.74Q127.71-5.95 127.10-6.61L127.10-6.61L127.10-6.61Q126.50-7.26 125.42-7.26"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "21.04px -1.31947px",
              animation:
                "1s linear -0.264s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M137.12-11.84L137.12-2.75L137.12-2.75Q137.12-1.89 137.48-1.47L137.48-1.47L137.48-1.47Q137.84-1.06 138.48-1.06L138.48-1.06L138.48-1.06Q138.78-1.06 139.07-1.13L139.07-1.13L139.07-1.13Q139.36-1.20 139.58-1.30L139.58-1.30L139.94-0.13L139.94-0.13Q139.65 0 139.22 0.10L139.22 0.10L139.22 0.10Q138.80 0.19 138.29 0.19L138.29 0.19L138.29 0.19Q137.73 0.19 137.26 0.03L137.26 0.03L137.26 0.03Q136.78-0.13 136.43-0.46L136.43-0.46L136.43-0.46Q136.08-0.80 135.88-1.33L135.88-1.33L135.88-1.33Q135.68-1.86 135.68-2.61L135.68-2.61L135.68-11.84L137.12-11.84"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "27.79px 0.345533px",
              animation:
                "1s linear -0.242s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M147.23-4.16L147.23-4.16L147.23-4.16Q147.23-4.78 147.03-5.34L147.03-5.34L147.03-5.34Q146.83-5.90 146.47-6.32L146.47-6.32L146.47-6.32Q146.11-6.74 145.62-6.98L145.62-6.98L145.62-6.98Q145.14-7.23 144.56-7.23L144.56-7.23L144.56-7.23Q143.30-7.23 142.59-6.40L142.59-6.40L142.59-6.40Q141.89-5.57 141.89-4.19L141.89-4.19L141.89-4.19Q141.89-3.57 142.09-3L142.09-3L142.09-3Q142.29-2.43 142.65-2.01L142.65-2.01L142.65-2.01Q143.01-1.58 143.50-1.34L143.50-1.34L143.50-1.34Q143.98-1.09 144.56-1.09L144.56-1.09L144.56-1.09Q145.18-1.09 145.68-1.31L145.68-1.31L145.68-1.31Q146.18-1.54 146.52-1.94L146.52-1.94L146.52-1.94Q146.86-2.35 147.05-2.91L147.05-2.91L147.05-2.91Q147.23-3.47 147.23-4.16zM140.42-4.16L140.42-4.16L140.42-4.16Q140.42-5.17 140.74-5.98L140.74-5.98L140.74-5.98Q141.06-6.78 141.61-7.34L141.61-7.34L141.61-7.34Q142.16-7.90 142.92-8.21L142.92-8.21L142.92-8.21Q143.68-8.51 144.56-8.51L144.56-8.51L144.56-8.51Q145.42-8.51 146.18-8.21L146.18-8.21L146.18-8.21Q146.94-7.90 147.50-7.34L147.50-7.34L147.50-7.34Q148.06-6.78 148.38-5.98L148.38-5.98L148.38-5.98Q148.70-5.18 148.70-4.19L148.70-4.19L148.70-4.19Q148.70-3.20 148.39-2.39L148.39-2.39L148.39-2.39Q148.08-1.58 147.52-1.01L147.52-1.01L147.52-1.01Q146.96-0.43 146.20-0.12L146.20-0.12L146.20-0.12Q145.44 0.19 144.56 0.19L144.56 0.19L144.56 0.19Q143.66 0.19 142.90-0.13L142.90-0.13L142.90-0.13Q142.14-0.45 141.59-1.02L141.59-1.02L141.59-1.02Q141.04-1.60 140.73-2.40L140.73-2.40L140.73-2.40Q140.42-3.20 140.42-4.16"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "37.355px 0.345533px",
              animation:
                "1s linear -0.22s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M156.80-4.16L156.80-4.16L156.80-4.16Q156.80-4.78 156.60-5.34L156.60-5.34L156.60-5.34Q156.40-5.90 156.04-6.32L156.04-6.32L156.04-6.32Q155.68-6.74 155.19-6.98L155.19-6.98L155.19-6.98Q154.70-7.23 154.13-7.23L154.13-7.23L154.13-7.23Q152.86-7.23 152.16-6.40L152.16-6.40L152.16-6.40Q151.46-5.57 151.46-4.19L151.46-4.19L151.46-4.19Q151.46-3.57 151.66-3L151.66-3L151.66-3Q151.86-2.43 152.22-2.01L152.22-2.01L152.22-2.01Q152.58-1.58 153.06-1.34L153.06-1.34L153.06-1.34Q153.55-1.09 154.13-1.09L154.13-1.09L154.13-1.09Q154.75-1.09 155.25-1.31L155.25-1.31L155.25-1.31Q155.74-1.54 156.09-1.94L156.09-1.94L156.09-1.94Q156.43-2.35 156.62-2.91L156.62-2.91L156.62-2.91Q156.80-3.47 156.80-4.16zM149.98-4.16L149.98-4.16L149.98-4.16Q149.98-5.17 150.30-5.98L150.30-5.98L150.30-5.98Q150.62-6.78 151.18-7.34L151.18-7.34L151.18-7.34Q151.73-7.90 152.49-8.21L152.49-8.21L152.49-8.21Q153.25-8.51 154.13-8.51L154.13-8.51L154.13-8.51Q154.99-8.51 155.75-8.21L155.75-8.21L155.75-8.21Q156.51-7.90 157.07-7.34L157.07-7.34L157.07-7.34Q157.63-6.78 157.95-5.98L157.95-5.98L157.95-5.98Q158.27-5.18 158.27-4.19L158.27-4.19L158.27-4.19Q158.27-3.20 157.96-2.39L157.96-2.39L157.96-2.39Q157.65-1.58 157.09-1.01L157.09-1.01L157.09-1.01Q156.53-0.43 155.77-0.12L155.77-0.12L155.77-0.12Q155.01 0.19 154.13 0.19L154.13 0.19L154.13 0.19Q153.23 0.19 152.47-0.13L152.47-0.13L152.47-0.13Q151.71-0.45 151.16-1.02L151.16-1.02L151.16-1.02Q150.61-1.60 150.30-2.40L150.30-2.40L150.30-2.40Q149.98-3.20 149.98-4.16"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "45.315px -0.719467px",
              animation:
                "1s linear -0.198s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M160.54-8.02L160.85-10.64L161.95-10.64L161.95-8.02L164.26-8.02L164.26-6.77L161.95-6.77L161.95-2.75L161.95-2.75Q161.95-2.29 162.06-1.97L162.06-1.97L162.06-1.97Q162.16-1.65 162.34-1.46L162.34-1.46L162.34-1.46Q162.51-1.26 162.76-1.18L162.76-1.18L162.76-1.18Q163.01-1.09 163.28-1.09L163.28-1.09L163.28-1.09Q163.68-1.09 164.03-1.22L164.03-1.22L164.03-1.22Q164.38-1.34 164.70-1.52L164.70-1.52L165.10-0.37L165.10-0.37Q164.78-0.18 164.24 0.01L164.24 0.01L164.24 0.01Q163.70 0.19 163.10 0.19L163.10 0.19L163.10 0.19Q161.90 0.19 161.21-0.59L161.21-0.59L161.21-0.59Q160.51-1.38 160.51-2.86L160.51-2.86L160.51-6.77L159.07-6.77L159.07-8.02L160.54-8.02"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "53.525px -1.31947px",
              animation:
                "1s linear -0.176s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M166.54 0L166.54-11.84L167.98-11.84L167.98-7.46L167.98-7.46Q168.14-7.63 168.38-7.82L168.38-7.82L168.38-7.82Q168.62-8.02 168.92-8.17L168.92-8.17L168.92-8.17Q169.22-8.32 169.58-8.42L169.58-8.42L169.58-8.42Q169.95-8.51 170.38-8.51L170.38-8.51L170.38-8.51Q171.10-8.51 171.77-8.22L171.77-8.22L171.77-8.22Q172.43-7.94 172.94-7.39L172.94-7.39L172.94-7.39Q173.44-6.85 173.74-6.05L173.74-6.05L173.74-6.05Q174.05-5.25 174.05-4.24L174.05-4.24L174.05-4.24Q174.05-3.25 173.73-2.43L173.73-2.43L173.73-2.43Q173.41-1.62 172.88-1.03L172.88-1.03L172.88-1.03Q172.35-0.45 171.67-0.13L171.67-0.13L171.67-0.13Q170.99 0.19 170.29 0.19L170.29 0.19L170.29 0.19Q169.57 0.19 168.94-0.06L168.94-0.06L168.94-0.06Q168.30-0.32 167.89-0.69L167.89-0.69L167.89 0L166.54 0zM172.58-4.19L172.58-4.19L172.58-4.19Q172.58-4.91 172.38-5.48L172.38-5.48L172.38-5.48Q172.18-6.05 171.83-6.45L171.83-6.45L171.83-6.45Q171.49-6.85 171.03-7.06L171.03-7.06L171.03-7.06Q170.58-7.26 170.06-7.26L170.06-7.26L170.06-7.26Q169.38-7.26 168.82-6.92L168.82-6.92L168.82-6.92Q168.27-6.58 167.98-6.18L167.98-6.18L167.98-1.73L167.98-1.73Q168.35-1.44 168.85-1.26L168.85-1.26L168.85-1.26Q169.34-1.09 169.90-1.09L169.90-1.09L169.90-1.09Q170.48-1.09 170.98-1.31L170.98-1.31L170.98-1.31Q171.47-1.54 171.82-1.94L171.82-1.94L171.82-1.94Q172.18-2.35 172.38-2.92L172.38-2.92L172.38-2.92Q172.58-3.49 172.58-4.19"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "62.785px 0.345533px",
              animation:
                "1s linear -0.154s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M182.22-4.16L182.22-4.16L182.22-4.16Q182.22-4.78 182.02-5.34L182.02-5.34L182.02-5.34Q181.82-5.90 181.46-6.32L181.46-6.32L181.46-6.32Q181.10-6.74 180.62-6.98L180.62-6.98L180.62-6.98Q180.13-7.23 179.55-7.23L179.55-7.23L179.55-7.23Q178.29-7.23 177.58-6.40L177.58-6.40L177.58-6.40Q176.88-5.57 176.88-4.19L176.88-4.19L176.88-4.19Q176.88-3.57 177.08-3L177.08-3L177.08-3Q177.28-2.43 177.64-2.01L177.64-2.01L177.64-2.01Q178.00-1.58 178.49-1.34L178.49-1.34L178.49-1.34Q178.98-1.09 179.55-1.09L179.55-1.09L179.55-1.09Q180.18-1.09 180.67-1.31L180.67-1.31L180.67-1.31Q181.17-1.54 181.51-1.94L181.51-1.94L181.51-1.94Q181.86-2.35 182.04-2.91L182.04-2.91L182.04-2.91Q182.22-3.47 182.22-4.16zM175.41-4.16L175.41-4.16L175.41-4.16Q175.41-5.17 175.73-5.98L175.73-5.98L175.73-5.98Q176.05-6.78 176.60-7.34L176.60-7.34L176.60-7.34Q177.15-7.90 177.91-8.21L177.91-8.21L177.91-8.21Q178.67-8.51 179.55-8.51L179.55-8.51L179.55-8.51Q180.42-8.51 181.18-8.21L181.18-8.21L181.18-8.21Q181.94-7.90 182.50-7.34L182.50-7.34L182.50-7.34Q183.06-6.78 183.38-5.98L183.38-5.98L183.38-5.98Q183.70-5.18 183.70-4.19L183.70-4.19L183.70-4.19Q183.70-3.20 183.38-2.39L183.38-2.39L183.38-2.39Q183.07-1.58 182.51-1.01L182.51-1.01L182.51-1.01Q181.95-0.43 181.19-0.12L181.19-0.12L181.19-0.12Q180.43 0.19 179.55 0.19L179.55 0.19L179.55 0.19Q178.66 0.19 177.90-0.13L177.90-0.13L177.90-0.13Q177.14-0.45 176.58-1.02L176.58-1.02L176.58-1.02Q176.03-1.60 175.72-2.40L175.72-2.40L175.72-2.40Q175.41-3.20 175.41-4.16"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "71.33px 0.325533px",
              animation:
                "1s linear -0.132s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M184.26-0.78L187.22-4.22L184.37-7.52L185.49-8.50L188.06-5.17L190.59-8.43L191.66-7.52L188.90-4.29L191.94-0.78L190.85 0.14L188.05-3.33L185.28 0.11L184.26-0.78"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "84.775px -1.33447px",
              animation:
                "1s linear -0.11s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M198.18 0L198.18-11.84L199.62-11.84L199.62-5.07L203.38-8.48L204.21-7.57L200.99-4.72L204.91-0.80L203.92 0.16L199.62-4.27L199.62 0L198.18 0"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "92.455px 0.345533px",
              animation:
                "1s linear -0.088s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M212.85-3.57L207.02-3.57L207.02-3.57Q207.17-2.40 207.84-1.74L207.84-1.74L207.84-1.74Q208.51-1.09 209.62-1.09L209.62-1.09L209.62-1.09Q210.34-1.09 210.91-1.24L210.91-1.24L210.91-1.24Q211.49-1.39 212.02-1.63L212.02-1.63L212.35-0.40L212.35-0.40Q211.78-0.13 211.06 0.03L211.06 0.03L211.06 0.03Q210.34 0.19 209.44 0.19L209.44 0.19L209.44 0.19Q208.54 0.19 207.82-0.10L207.82-0.10L207.82-0.10Q207.10-0.40 206.60-0.95L206.60-0.95L206.60-0.95Q206.10-1.50 205.82-2.31L205.82-2.31L205.82-2.31Q205.55-3.12 205.55-4.16L205.55-4.16L205.55-4.16Q205.55-5.09 205.82-5.89L205.82-5.89L205.82-5.89Q206.10-6.69 206.59-7.27L206.59-7.27L206.59-7.27Q207.09-7.86 207.78-8.18L207.78-8.18L207.78-8.18Q208.46-8.51 209.31-8.51L209.31-8.51L209.31-8.51Q210.21-8.51 210.88-8.21L210.88-8.21L210.88-8.21Q211.55-7.90 212.00-7.38L212.00-7.38L212.00-7.38Q212.45-6.85 212.67-6.14L212.67-6.14L212.67-6.14Q212.90-5.44 212.90-4.66L212.90-4.66L212.90-4.66Q212.90-4.10 212.85-3.57L212.85-3.57zM209.28-7.26L209.28-7.26L209.28-7.26Q208.34-7.26 207.74-6.62L207.74-6.62L207.74-6.62Q207.14-5.98 207.01-4.74L207.01-4.74L211.57-4.74L211.57-4.74Q211.57-5.95 210.96-6.61L210.96-6.61L210.96-6.61Q210.35-7.26 209.28-7.26"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "100.91px 2.10553px",
              animation:
                "1s linear -0.066s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M215.02 3.71L215.02 3.71L215.02 3.71Q214.54 3.71 214.18 3.62L214.18 3.62L214.18 3.62Q213.82 3.52 213.62 3.39L213.62 3.39L213.94 2.19L213.94 2.19Q214.13 2.30 214.41 2.38L214.41 2.38L214.41 2.38Q214.69 2.46 215.06 2.46L215.06 2.46L215.06 2.46Q215.39 2.46 215.69 2.37L215.69 2.37L215.69 2.37Q215.98 2.27 216.26 2.03L216.26 2.03L216.26 2.03Q216.53 1.79 216.78 1.38L216.78 1.38L216.78 1.38Q217.02 0.96 217.26 0.34L217.26 0.34L217.63-0.62L216.86-0.62L213.62-7.94L214.90-8.51L218.00-1.58L220.42-8.45L221.74-7.94L218.69 0.42L218.69 0.42Q218.34 1.38 217.94 2.01L217.94 2.01L217.94 2.01Q217.55 2.64 217.10 3.02L217.10 3.02L217.10 3.02Q216.66 3.41 216.14 3.56L216.14 3.56L216.14 3.56Q215.62 3.71 215.02 3.71"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "106.255px 3.62553px",
              animation:
                "1s linear -0.044s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M221.95-0.88L221.95-0.88L221.95-0.88Q221.95-1.38 222.23-1.66L222.23-1.66L222.23-1.66Q222.51-1.95 223.02-1.95L223.02-1.95L223.02-1.95Q223.44-1.95 223.77-1.63L223.77-1.63L223.77-1.63Q224.10-1.31 224.10-0.88L224.10-0.88L224.10-0.88Q224.10-0.40 223.79-0.10L223.79-0.10L223.79-0.10Q223.49 0.19 223.02 0.19L223.02 0.19L223.02 0.19Q222.59 0.19 222.27-0.13L222.27-0.13L222.27-0.13Q221.95-0.45 221.95-0.88"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "111.055px 3.62553px",
              animation:
                "1s linear -0.022s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M226.75-0.88L226.75-0.88L226.75-0.88Q226.75-1.38 227.03-1.66L227.03-1.66L227.03-1.66Q227.31-1.95 227.82-1.95L227.82-1.95L227.82-1.95Q228.24-1.95 228.57-1.63L228.57-1.63L228.57-1.63Q228.90-1.31 228.90-0.88L228.90-0.88L228.90-0.88Q228.90-0.40 228.59-0.10L228.59-0.10L228.59-0.10Q228.29 0.19 227.82 0.19L227.82 0.19L227.82 0.19Q227.39 0.19 227.07-0.13L227.07-0.13L227.07-0.13Q226.75-0.45 226.75-0.88"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
          <g
            className="path"
            style={{
              transform: "scale(1)",
              transformOrigin: "115.855px 3.62553px",
              animation:
                "1s linear 0s infinite normal forwards running beat-a99601fd-8d3b-47f5-a66c-4e6da5899294",
            }}
          >
            <path
              d="M231.55-0.88L231.55-0.88L231.55-0.88Q231.55-1.38 231.83-1.66L231.83-1.66L231.83-1.66Q232.11-1.95 232.62-1.95L232.62-1.95L232.62-1.95Q233.04-1.95 233.37-1.63L233.37-1.63L233.37-1.63Q233.70-1.31 233.70-0.88L233.70-0.88L233.70-0.88Q233.70-0.40 233.39-0.10L233.39-0.10L233.39-0.10Q233.09 0.19 232.62 0.19L232.62 0.19L232.62 0.19Q232.19 0.19 231.87-0.13L231.87-0.13L231.87-0.13Q231.55-0.45 231.55-0.88"
              fill="#ffffff"
              stroke="none"
              strokeWidth="none"
              transform="translate(-116.76998901367188,4.50553348795574)"
              style={{
                fill: "rgb(255, 255, 255)",
              }}
            />
          </g>
        </g>
      </g>
    </g>
    <style id="beat-a99601fd-8d3b-47f5-a66c-4e6da5899294" data-anikit="">
      {
        "@keyframes beat-a99601fd-8d3b-47f5-a66c-4e6da5899294\n{\n  0% {\n    animation-timing-function: cubic-bezier(0.1028,0.2484,0.1372,0.849);\n    transform: scale(1);\n  }\n  34% {\n    animation-timing-function: cubic-bezier(0.7116,0.2095,0.8159,0.6876);\n    transform: scale(1.2);\n  }\n  68% {\n    animation-timing-function: cubic-bezier(0.1475,0.2888,0.294,0.883);\n    transform: scale(1.0268);\n  }\n  84% {\n    animation-timing-function: cubic-bezier(0.8176,0.2193,0.867,0.6889);\n    transform: scale(1.0932);\n  }\n  100% {\n    transform: scale(1);\n  }\n}"
      }
    </style>
  </svg>
);
export default LoadingTextLootbox;
