import React from 'react';

const RegisterWalletOverlay = ({ registerImmutable, unregisteredWallet }) => {
    return (
        <div className="main-overlay" style={{ color: 'white' }}>
            <div className='second-overlay'>
                <div className='third-overlay'>
                    <h2>
                        {`Wallet not on IMX '${unregisteredWallet.substring(0, 7)}...${unregisteredWallet.substring(unregisteredWallet.length - 5)}'`}
                    </h2>
                    <p style={{ color: 'white' }}>We couldn't find the wallet associated with your account in IMX yet.</p>
                    <p style={{ color: 'white' }}>To enjoy full access to in-game items like pets, skins, and lootboxes,</p>
                    <p style={{ color: 'white' }}>please register your wallet by clicking the button below: </p>
                    <button className="buttonrng" onClick={registerImmutable} style={{ marginTop: 15 }}>Register</button>
                </div>
            </div>
        </div >
    );
};

export default RegisterWalletOverlay;