import React from "react";

const Popup = ({ title, content, onClose }) => {
  return (
    <div className="position-fixed alert-container">
      <div className="close" onClick={onClose} />
      <div className="toast-container">
        <div className="toast-icon toast-success">
          <h2>{title}</h2>
        </div>
        <div className="toast-content">{content}</div>
      </div>
    </div>
  );
};

export default Popup;
