import React from 'react';

const ErrorShowing = ({ errorText }) => {
  return (
    <div style={{ 
      padding: '20px',
      backgroundColor: '#25C486',
      color: 'white',
      fontSize: '16px',
      textAlign: 'center',
    }}>
      {errorText}
    </div>
  );
};

export default ErrorShowing;