import * as React from "react";
const ForgeLoader = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        width={199}
        height={199}
        style={{
            shapeRendering: "auto",
            display: "block",
        }}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g>
            <circle
                strokeLinecap="round"
                fill="none"
                strokeDasharray="69.11503837897544 69.11503837897544"
                stroke="#24c385"
                strokeWidth={8}
                r={44}
                cy={50}
                cx={50}
            >
                <animateTransform
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    dur="1s"
                    type="rotate"
                    attributeName="transform"
                />
            </circle>
            <circle
                strokeLinecap="round"
                fill="none"
                strokeDashoffset={54.97787143782138}
                strokeDasharray="54.97787143782138 54.97787143782138"
                stroke="#18a07e"
                strokeWidth={8}
                r={35}
                cy={50}
                cx={50}
            >
                <animateTransform
                    values="0 50 50;-360 50 50"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    dur="1s"
                    type="rotate"
                    attributeName="transform"
                />
            </circle>
            <g />
        </g>
    </svg>
);
export default ForgeLoader;
