import { ERC721TokenType, Link } from "@imtbl/imx-sdk";
import { Sphereone, linkAddress, Toast } from "../utils/Global";
import { SupportedChains } from "websdk";
import { sphereoneSdk } from "../utils/SphereOne";
import { useNavigate } from 'react-router-dom';
import { Context } from "../context/WalletContext";
import { Button } from "../components/Button";
import React, { useState, useContext, useEffect } from 'react';
import Popup from "../components/Popup";
import ForgeLoader from "../assets/svg/ForgeLoader";
import defaultImage from "../assets/img/PlaceholderNft.png";
import ErrorShowing from "../components/ErrorShowing";
const immutableService = require('../context/ImmutableService');

const SkinTrade = () => {
    const link = new Link(linkAddress);
    const navigate = useNavigate();

    const { state } = useContext(Context);

    const [carouselImages, setCarouselImages] = useState([defaultImage, defaultImage, defaultImage, defaultImage, defaultImage]);
    const [nftItems, setNftItems] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [showLoader, setShowLoader] = useState(false)

    const handleNFTClick = (nft) => {
        if (selectedNFTs.length < 5 && !selectedNFTs.some(selected => selected.token_id === nft.token_id)) {
            setSelectedNFTs([...selectedNFTs, nft]);
            setCarouselImages((prevImages) => {
                const newImages = [...prevImages];
                newImages[selectedNFTs.length] = nft.image_url;
                return newImages;
            });
        }
    };

    const handleReset = () => {
        setSelectedNFTs([]);
        setCarouselImages([defaultImage, defaultImage, defaultImage, defaultImage, defaultImage]);
    };

    const handleBurnSkins = () => {
        if (state?.user?.authType === Sphereone) {
            try {
                sphereoneSdk.openPinCode("SEND_NFT")
            } catch (e) {
                console.error(e)
            }
        }
        setShowPopup(true)
    };

    const sendSkinFromForge = async () => {
        const maxRetries = 6;

        const tradeSkins = async (retryCount = 0) => {
            try {
                // Step 1: Send request to trade 5 skins
                const tradeRequest = await fetch('https://api.qorbiworld.com/v1/nft/trade-5-skins', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        walletAddress: state?.userWallet?.string,
                    }),
                });

                if (!tradeRequest.ok) {
                    throw new Error('Failed to complete trade');
                }

                const tradeResponse = await tradeRequest.json();
                const followId = tradeResponse.followId;

                console.log('Trade request initiated with followId:', followId);

                // Step 2: Polling loop to check status until it's completed or failed
                const checkStatus = async (currentRetry = 0) => {
                    let status = '';

                    while (status !== 'completed' && status !== 'failed') {
                        if (currentRetry >= maxRetries) {
                            console.log(`Reached max retries for followId: ${followId}, requesting new followId...`);
                            return tradeSkins(retryCount + 1);
                        }

                        await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds

                        const followRequest = await fetch(`https://api.qorbiworld.com/v1/nft/trade-5-skins/${followId}`);
                        if (!followRequest.ok) {
                            throw new Error('Failed to fetch follow status');
                        }

                        const followResponse = await followRequest.json();
                        status = followResponse.data.status;

                        console.log('Current status:', status);
                        currentRetry++;
                    }

                    console.log('Trade process completed or failed with status:', status);

                    // Handle completion with toast notification
                    if (status === 'completed') {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Congratulations! Your skins have been successfully traded for a higher rarity item.',
                            type: 'success',
                        });
                    } else {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Oops! Trade process failed. Please contact us using discord. CODE: 002',
                            type: 'error',
                        });
                    }
                };

                await checkStatus();
            } catch (error) {
                console.error('Error:', error);
                setShowLoader(false);
                setTimeout(() => {
                    navigate('/my-assets');
                }, 5000);
                Toast.set({
                    show: true,
                    desc: 'Something went wrong. Please contact us using discord. CODE: 003',
                    type: 'error',
                });
            }
        };

        await tradeSkins();
    };

    const verify = async () => {
        setShowPopup(false);
        setShowLoader(true)
        const successfulTransfers = []
        const array = [];
        const nfts = [];
        selectedNFTs.forEach((element) => {
            array.push(element.token_id);
            nfts.push({
                type: ERC721TokenType.ERC721, // Must be of type ERC721
                tokenId: element.token_id, // the token ID, SphereOne and IMX give different name to that field
                tokenAddress: element.token_address, // the collection address / contract address this token belongs to
                toAddress: "0xFd2B3962F91b326409872Cd179EA0dfB328327F1", // the wallet address this token is being transferred to
            });
        });
        if (state?.user?.authType === Sphereone) {
            for (const element of nfts) {
                const addressAsset = nftItems.find(item => item.token_id === element.tokenId);
                try {
                    const responseTransfer = await sphereoneSdk.transferNft({
                        chain: SupportedChains.IMMUTABLE,
                        fromAddress: addressAsset.user,
                        toAddress: element.toAddress,
                        nftTokenAddress: element.tokenAddress,
                        tokenId: element.tokenId,
                        reason: "Trade Egg for Pet"
                    });

                    if (responseTransfer.data) {
                        successfulTransfers.push(element);
                    } else if (responseTransfer.error) {
                        setErrorMsg("Something went wrong. Please contact us at info@qorgaming.com");
                        console.error("Res: ", responseTransfer.error);
                        return;
                    }
                } catch (e) {
                    if (e.message === "Error: There was an error getting the wrapped dek") {
                        setErrorMsg("You need to input a valid PIN");
                    } else {
                        setErrorMsg("Token transfer went wrong: ", e);
                    }
                    return;
                }
            }

            if (successfulTransfers.length === 5) {
                // transfer token to wallet admin
                sendSkinFromForge();
            } else if (successfulTransfers.length > 0) {
                setErrorMsg(`${successfulTransfers.length} Skins have been sent, Please contact info@qorgaming.com`);
            } else {
                setErrorMsg("All transfers failed. Please contact info@qorgaming.com");
            }
        } else {
            let response = await link.batchNftTransfer(nfts);
            // transfer token to wallet admin
            if (response.result.length > 0) {
                sendSkinFromForge()
            }
        }

    };
    useEffect(() => {
        if (state?.user?.authType === Sphereone) {
            sphereoneSdk.pinCodeHandler();
        }
        const user = state?.userWallet?.string;
        const skinCollection = ['0x8a2315e441786e10ff1962387407628108b9ba33']
        const updateAssets = (assets) => {
            setNftItems(() => {
                const commonSkinsNfts = assets.filter(item => item.metadata?.rarity === "Common");
                return commonSkinsNfts;
            });
        };
        const fetchSkins = async (walletAddress) => {
            await immutableService.getAssetsFromCollections(walletAddress, skinCollection, updateAssets);
        };
        if (user !== undefined && user !== null) {
            fetchSkins(user).then();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bundle-container">
            <div className="bundle-top-segment">
                <div className="bundle-left-part">
                    <h1>Forge Your Legacy with Epic and Legendary Skins!</h1>
                    <p>Unleash the might of the forge and transform your ordinary skins into extraordinary treasures.</p>
                    <p className="conditional-text">Burn your existing skins to unlock unparalleled epic and legendary rewards, and elevate your collection to new heights!</p>
                    <div className="button-container">
                        {selectedNFTs.length > 0 && (
                            <button className="carousel-button-reset" onClick={handleReset}>
                                Reset
                            </button>
                        )}
                        {selectedNFTs.length === 5 && (
                            <button className="carousel-button-burn" onClick={handleBurnSkins}>
                                Burn Skins
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {showLoader && (
                <div className="trade-loader-container">
                    <div className="trade-loader-inner-container">
                        <ForgeLoader />
                        <h2 style={{ margin: '10px 0' }}>Burning the skins</h2>
                        <h2 style={{ margin: '10px 0' }}>Using the ashes to forge something epic!</h2>
                    </div>
                </div>
            )}

            {errorMsg && <ErrorShowing errorText={errorMsg} />}
            {showPopup ? (
                <Popup
                    onClose={() => setShowPopup(false)}
                    title="Confirmation required"
                    content={
                        <>
                            <p>
                                Are you sure you want to send your skins to the forge? You cannot
                                unbreak the broken.
                            </p>
                            <Button label="Yes, Please" onClick={() => verify()} />
                            <span style={{ width: 10, display: "inline-block" }} />
                            <Button transparent label="No" onClick={() => setShowPopup(false)} />
                        </>
                    }
                />
            ) : null}
            <div className="bundle-bottom-segment">
                <div className="bundle-static-images">
                    {carouselImages.map((image, index) => (
                        <div className="bundle-static-image-item" key={index}>
                            <img src={image} alt={`Static ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 right-side">
                            <h2>Your Assets</h2>
                            <div className="row">
                                {nftItems?.length > 0 ? (
                                    nftItems.map((item) => (
                                        <div
                                            className={`col-md-3 mb-3`}
                                            key={item.token_id}
                                            onClick={() => handleNFTClick(item)}
                                        >
                                            <div
                                                className="bg"
                                                style={{
                                                    display: 'flex', justifyContent: 'center',
                                                    // backgroundImage: `url(${defaultImage})`,
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <img className={`tokenImage ${selectedNFTs.some(selected => selected.token_id === item.token_id) ? 'selected-nft' : ''}`}
                                                    src={item.image_url}
                                                    style={{ width: "50vw" }}
                                                    alt={item.name}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <h2 className="text-center">
                                        There are no assets for Qorbi World in your wallet
                                    </h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkinTrade;
