import React, { createContext, useContext, useState } from 'react';

const baseData = {
  sphereOneUser: null,
  setSphereOneUser: () => { },
  qorbiUserData: null,
  setQorbiUserData: () => { },
  startMission: false,
  setStartMission: () => { },
  multipleWallets: false,
  setMultipleWallets: () => { },
  devicedUsed: null,
  setDevicedUsed: () => { },
  showLogin: false,
  setShowLogin: () => { },
  registerWallet: { show: false, wallet: null },
  setRegisterWallet: () => { },
  registerUser: false,
  setRegisterUser: () => { },
}
const SphereOneContext = createContext(baseData);

const SphereOneProvider = ({ children }) => {
  const [sphereOneUser, setSphereOneUser] = useState(baseData.sphereOneUser);
  const [qorbiUserData, setQorbiUserData] = useState(baseData.qorbiUserData);
  const [startMission, setStartMission] = useState(baseData.startMission);
  const [sphereOneWallets, setSphereOneWallets] = useState(baseData.sphereOneWallets);
  const [multipleWallets, setMultipleWallets] = useState(baseData.multipleWallets);
  const [devicedUsed, setDevicedUsed] = useState(baseData.devicedUsed);
  const [showLogin, setShowLogin] = useState(baseData.showLogin);
  const [registerWallet, setRegisterWallet] = useState(baseData.registerWallet);
  const [registerUser, setRegisterUser] = useState(baseData.registerUser);

  return (
    <SphereOneContext.Provider value={{
      sphereOneUser, sphereOneWallets, multipleWallets, devicedUsed, showLogin, registerWallet, registerUser, startMission, qorbiUserData,
      setSphereOneUser, setSphereOneWallets, setMultipleWallets, setDevicedUsed, setShowLogin, setRegisterWallet, setRegisterUser, setStartMission, setQorbiUserData,
    }}>
      {children}
    </SphereOneContext.Provider>
  );
};

export default SphereOneProvider;

export const useSphereOneProvider = () => useContext(SphereOneContext);
