import * as React from "react";

const Wallet = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="39.584" height="39.584" viewBox="0 0 39.584 39.584">
        <g id="empty-wallet" transform="translate(-236 -188)">
            <path id="Vector" d="M0,0H39.584V39.584H0Z" transform="translate(236 188)" fill="none" opacity="0" />
            <path id="Vector-2" data-name="Vector" d="M20.369,3.134V9.4H17.9V3.134a.657.657,0,0,0-.907-.61L3.909,7.455A2.216,2.216,0,0,0,2.474,9.55v1.105A6.161,6.161,0,0,0,0,15.6V9.55a4.7,4.7,0,0,1,3.035-4.4L16.13.2A3.258,3.258,0,0,1,17.236,0,3.142,3.142,0,0,1,20.369,3.134Z" transform="translate(240.123 191.381)" fill="#fff" />
            <path id="Vector-3" data-name="Vector" d="M4.956.825V2.474a.823.823,0,0,1-.808.825H1.739A1.707,1.707,0,0,1,.008,1.8,1.647,1.647,0,0,1,.486.478,1.568,1.568,0,0,1,1.64,0h2.49A.827.827,0,0,1,4.956.825Z" transform="translate(266.505 211.091)" fill="#fff" />
            <path id="Vector-4" data-name="Vector" d="M28.006,8.577h1.682a1.654,1.654,0,0,0,1.649-1.649V6.2a6.215,6.215,0,0,0-6.2-6.2H6.2A6.113,6.113,0,0,0,2.474,1.253,6.161,6.161,0,0,0,0,6.2V17.3a6.215,6.215,0,0,0,6.2,6.2H25.136a6.215,6.215,0,0,0,6.2-6.2v-.313a1.654,1.654,0,0,0-1.649-1.649H28.253a3.58,3.58,0,0,1-3.513-2.507,3.4,3.4,0,0,1,3.266-4.255Zm-9.038-.33H7.422a1.237,1.237,0,0,1,0-2.474H18.967a1.237,1.237,0,1,1,0,2.474Z" transform="translate(240.123 200.782)" fill="#fff" />
        </g>
    </svg>
);

export default Wallet;
