import * as React from "react";
const Success = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={105.236}
    height={105.236}
    viewBox="0 0 105.236 105.236"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.5}
        y1={1}
        x2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#38ef7d" />
        <stop offset={1} stopColor="#11998e" />
      </linearGradient>
      <filter
        id="Elipse_1138"
        x={0}
        y={0}
        width={105.236}
        height={105.236}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={5} input="SourceAlpha" />
        <feGaussianBlur stdDeviation={5} result="blur" />
        <feFlood floodOpacity={0.098} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="me_gusta"
      data-name="me gusta"
      transform="translate(1401.16 -253.184)"
    >
      <g
        id="Grupo_17906"
        data-name="Grupo 17906"
        transform="translate(-1386.16 263.184)"
      >
        <g transform="matrix(1, 0, 0, 1, -15, -10)" filter="url(#Elipse_1138)">
          <circle
            id="Elipse_1138-2"
            data-name="Elipse 1138"
            cx={37.618}
            cy={37.618}
            r={37.618}
            transform="translate(90.24 85.24) rotate(180)"
            fill="url(#linear-gradient)"
          />
        </g>
        <g id="noun-like-1555605" transform="translate(20.502 22.35)">
          <g
            id="Grupo_17973"
            data-name="Grupo 17973"
            transform="translate(0 0)"
          >
            <path
              id="Trazado_70858"
              data-name="Trazado 70858"
              d="M7.5,26.705H1.786a.943.943,0,0,0-.953.953V42.9a.943.943,0,0,0,.953.953H7.5a.943.943,0,0,0,.952-.953V27.658A.942.942,0,0,0,7.5,26.705Z"
              transform="translate(-0.833 -14.345)"
              fill="rgba(255,255,255,0.96)"
            />
            <path
              id="Trazado_70859"
              data-name="Trazado 70859"
              d="M42.538,18.363a3.015,3.015,0,0,0-3.1-3.124H30.207a10.32,10.32,0,0,0,.79-8.1A4.112,4.112,0,0,0,27.372,4.2l-.027,0a2,2,0,0,0-2.111,1.827c-.237,2.407-1.292,6.665-2.8,8.177a13.4,13.4,0,0,1-4.169,2.689c-.261.128-.547.267-.849.418.005.065.009.131.009.2V32.6l.645.221a26.279,26.279,0,0,0,9.494,1.914h7.462a3.015,3.015,0,0,0,3.1-3.124,3.366,3.366,0,0,0-.278-1.345,2.947,2.947,0,0,0,1.712-.947,3.2,3.2,0,0,0,.774-2.123,3.364,3.364,0,0,0-.278-1.343,3.015,3.015,0,0,0,2.486-3.072,3.229,3.229,0,0,0-.846-2.208A3.236,3.236,0,0,0,42.538,18.363Z"
              transform="translate(-8.308 -4.197)"
              fill="rgba(255,255,255,0.96)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Success;