import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { differenceInSeconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const BetaCountdown = () => {

    const navigate = useNavigate();

    const timeZone = 'America/New_York';

    const calculateTimeLeft = () => {
        const now = new Date();
        const nowInEST = toZonedTime(now, timeZone);
        const difference = differenceInSeconds('2024-09-09T08:00:00', nowInEST);
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (60 * 60 * 24)),
                hours: Math.floor((difference / (60 * 60)) % 24),
                minutes: Math.floor((difference / 60) % 60),
                seconds: Math.floor(difference % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    return (
        <div>
            <div className="beta-countdown-container">
                <h1 className="countdown-title">One Tap Beta Closing Countdown</h1>
                <p className="countdown-description">
                    Our Beta will be closing soon. Anyone with access will continue to earn and play. Join now before it closes!
                </p>
                <div className="countdown-timer">
                    {timerComponents.length ? timerComponents : <span>Beta has ended!</span>}
                </div>
            </div>
            <div className='button-container' style={{ marginTop: 0 }}>
                <button className="btnTransparent" onClick={() => { navigate('/profile') }}>
                    Register Now
                </button>
            </div>
        </div>
    );
};

export default BetaCountdown;
