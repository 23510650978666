import * as React from "react";
const ImmutableLogo = (props) => (
    <svg
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 2500 2500"
        style={{
            enableBackground: "new 0 0 2500 2500",
        }}
        width={25}
        height={25}
        xmlSpace="preserve"
        {...props}
    >
        <g id="Layer_x0020_1">
            <g>
                <path d="M1859,1857c0,0-275-321-366-428c-10-12-14-29-10-44c3-8,9-18,15-24l42-47l469,542h-149L1859,1857z M1569,1857l-340-397    l-17-22c-16-19-16-47,1-66l634-739h157l-638,729c-17,18-17,47-1,65l368,428h-164V1857z M1173,1274c-131,152-500,583-500,583H509    l502-582c16-19,16-46,0-65L513,633h158l501,582c13,17,15,42,1,58l0,0V1274z M1167,1604l-49,57l-168,196H798l322-375    c0,0,36,43,48,57C1184,1558,1184,1586,1167,1604L1167,1604z M948,634c0,0,270,323,368,423c16,16,13,36-1,53c-52,61-53,62-53,62    L798,634h152H948z M1353,887l49-57l168-196h152l-322,375c0,0-36-43-48-57C1336,933,1336,905,1353,887L1353,887z M1251-1    C561-1,1,559,1,1249s560,1250,1250,1250s1250-560,1250-1250S1941-1,1251-1z" />
            </g>
        </g>
    </svg>
);
export default ImmutableLogo;
