import React, { useEffect, useState } from 'react';
import { globalRank } from '../utils/Global';
import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import ember_logo from '../assets/img/ember_icon.png'

const RankLeaderboard = ({ rank }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [localPage, setLocalPage] = useState(1);
    const [hoverMessage, setHoverMessage] = useState(null);
    const [maxPageFetched, setMaxPageFetched] = useState(1);

    const staticEarning = 0.25;
    const maxMatches = 600;

    useEffect(() => {
        setLoading(true);
        setLocalPage(1);
        fetchLeaderboard(1);
        setData([]);
        setMaxPageFetched(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rank]);

    const fetchLeaderboard = async (pageIteration) => {
        try {
            const response = await axios.get(`https://api.qorbiworld.com/sphere-one/leaderboard-rank/${rank}/${pageIteration}`);
            setData((prevData) => [...prevData, ...response.data]);
            if (pageIteration === 1) {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            setLoading(false);
        }
    };

    const handleNextLocalPage = () => {

        let maxIterations = 3;
        if (rank === 'Sapphire III' || rank === 'Sapphire II' || rank === 'Sapphire I') {
            maxIterations = 1;
        } else if (rank === 'Global Rank') {
            maxIterations = 45;
        }
        const stateOnFour = Math.round(localPage / 4) + 1;
        if (localPage % 4 === 2 && maxIterations >= stateOnFour && maxPageFetched < stateOnFour) {
            fetchLeaderboard(stateOnFour);
            setMaxPageFetched(stateOnFour)
        }

        if (localPage * 25 < data.length) {
            setLocalPage((prevLocalPage) => prevLocalPage + 1);
        }
    };

    const handlePreviousLocalPage = () => {
        if (localPage > 1) {
            setLocalPage((prevLocalPage) => prevLocalPage - 1);
        }
    };

    const handleMouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();
        setHoverMessage({
            // text: `This person owns a Qorb, their rewards have a ${globalRank[rank].multiplier}X multiplier`,
            text: `You can obtein the rewards by holding a Qorb!!!`,
            x: rect.left + window.scrollX + rect.width / 2,
            y: rect.top + window.scrollY - 10,
        });
    };

    const handleMouseLeave = () => {
        setHoverMessage(null);
    };

    if (loading) {
        return <p className='loading-message'>Looking for the leaderboard, I kinda forgot where it was...</p>
    }

    const startIndex = (localPage - 1) * 25;
    const currentData = data.slice(startIndex, startIndex + 25);

    return (
        <div className="rank-leaderboard">
            <h2 className='leaderboard-title'>{rank !== 'Global Rank' ? `${rank} Ranking` : 'Earning Ranking'}</h2>
            <table className='leaderboard-table'>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Username</th>
                        <th>Potential Earning</th>
                        <th>Ember Reward</th>
                        <th>
                            {rank !== 'Global Rank' ? 'Score' : 'Division'}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((player, index) => (
                        <tr key={index}>
                            <td>{player.rank}</td>
                            <td>{player.username}</td>
                            <td>
                                <div className='potential-earning'>
                                    {globalRank[player.rankTier].multiplier * staticEarning * maxMatches}
                                    <div className="logo" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <img alt="logo" src={qorbi_coin} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='potential-earning'>
                                    {globalRank[player.rankTier].emberReward}
                                    <div className="logo">
                                        <img alt="logo_ember" src={ember_logo} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                {rank !== 'Global Rank' ? player.score : player.rankTier}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {hoverMessage && (
                <div className="hover-floating-message" style={{ top: hoverMessage.y, left: hoverMessage.x }}>
                    {hoverMessage.text}
                </div>
            )}
            <div className="pagination-buttons">
                <button onClick={handlePreviousLocalPage} disabled={localPage === 1}>
                    Previous
                </button>
                <span style={{ color: "#FFFFFF" }}>Showing {currentData[0].rank} - {currentData[currentData.length - 1].rank}</span>
                <button onClick={handleNextLocalPage} disabled={localPage * 25 >= data.length}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default RankLeaderboard;