import React from "react";

const mintform = () => {
  return (
    <div className="black-bg pt-5">
      <div className="section">
        <div className="container">
          <div className="row">
            <iframe
              id="JotFormIFrame-232597908281164"
              title="Prizes for One Tap"
              onload="window.parent.scrollTo(0,0)"
              allowtransparency={true}
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/232597908281164"
              frameborder="0"
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                height: 539,
                border: "none",
              }}
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mintform;
