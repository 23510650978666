import { ImmutableXClient } from '@imtbl/imx-sdk';
import axios from 'axios';

export const getAssetsFromCollections = async (user, collections, updateAssets, pageSize = 100) => {
    const client = await ImmutableXClient.build({
        publicApiUrl: "https://api.x.immutable.com/v1",
    });

    for (const collection of collections) {
        let allAssets = [];
        let cursor = '';
        while (true) {
            const response = await client.getAssets({
                user,
                collection,
                cursor,
                page_size: pageSize,
            });
            if (collection === "0x938b5c1850106ebc82d49af65a030e1d9083f2f5") {
                const processedBadges = await addBadgeAbleToMint(response.result);
                allAssets = allAssets.concat(processedBadges);
            } else {
                allAssets = allAssets.concat(response.result);
            }
            updateAssets(allAssets);
            if (response.cursor) {
                cursor = response.cursor;
            } else {
                break;
            }
        }
    }
};

const addBadgeAbleToMint = async (assets) => {
    const enhancedAssets = [];
    for (const asset of assets) {
        const { token_id } = asset;
        try {
            const response = await axios.get(`https://api.qorbiworld.com/v1/check-badge-able-to-mint?tokenId=${token_id}`);
            if (response.data.isAble) {
                asset.isAble = true;
            } else {
                asset.isAble = false;
            }
        } catch (error) {
            console.error("Error while checking badge minting ability:", error.message);
            asset.isAble = false;
        }
        enhancedAssets.push(asset);
    }
    return enhancedAssets;
};