import './App.scss';
import { Routers } from './routers/routes';
import {Provider as WalletProvider} from './context/WalletContext'

function App() {
  return (
    <div>
      <WalletProvider>
        <Routers/>
      </WalletProvider>
    </div>
  );
}

export default App;
