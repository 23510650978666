import React from "react";
import { Link } from "react-router-dom";
import { MenuNoLogin } from "./Header/MenuNoLogin";

export const Footer = () => {

  return (
    <div className="footer">
      <div className="container">
        <div className="row top-section">
          <div className="col-12 col-md-2">
            <Link to="/">
              <img
                alt="logo"
                src="/assets/img/Green_Isotype.png"
                className="logo-footer"
              />
            </Link>
          </div>
          <div className="col-12 col-md-6">
            <ul className="navbar-footer item">
              <MenuNoLogin itemLocation={'footer'} />
            </ul>
          </div>
          <div className="col-12 col-md-4 social-container">
            <ul>
              <li>
                <a
                  href="https://twitter.com/QorbiWorld"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="social icon" src="/assets/img/Twitter_Icon.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitch.tv/qor_gaming"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="social icon" src="/assets/img/Twich_Icon.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/tvHSEAubzn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="social icon" src="/assets/img/Discord_Icon.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://qorbiworld.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="social icon" src="/assets/img/medium-white.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 bottom-footer">
            <p>© {new Date().getFullYear()} All Rights Reserved Qorbi World an Estonia Company.</p>
            <ul className="payment">
              <li>We accept the following payment methods:</li>
              <li>
                <img src="/assets/img/visa.png" alt="visa" />
              </li>
              <li>
                <img src="/assets/img/master-card.png" alt="visa" />
              </li>
              <li>
                <img src="/assets/img/discover.png" alt="visa" />
              </li>
              <li>
                <img src="/assets/img/american-express.png" alt="visa" />
              </li>
            </ul>
            <div className="terms">
              <ul>
                <li>
                  <a
                    href="https://whitepaper.qorbiworld.com/qorbi-world/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms |
                  </a>
                </li>
                <li>
                  <a
                    href="https://whitepaper.qorbiworld.com/qorbi-world/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
