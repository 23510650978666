import * as React from "react";
const TreasureButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={24.592}
    viewBox="0 0 80 44.63"
    {...props}
  >
    <g id="noun-treasure-chest-5772146" transform="translate(-10 -27.68)">
      <path
        id="Trazado_71013"
        data-name="Trazado 71013"
        d="M24.21,44.36a.836.836,0,0,0,.43.11.856.856,0,0,0,.75-.43.868.868,0,0,0-.32-1.18L14.01,36.51a.865.865,0,1,0-.86,1.5Z"
      />
      <path
        id="Trazado_71014"
        data-name="Trazado 71014"
        d="M25.39,51.25a.865.865,0,0,0-1.18-.32L13.15,57.28a.865.865,0,0,0-.32,1.18.856.856,0,0,0,.75.43.947.947,0,0,0,.43-.11l11.06-6.35A.868.868,0,0,0,25.39,51.25Z"
      />
      <path
        id="Trazado_71015"
        data-name="Trazado 71015"
        d="M10,47.65a.86.86,0,0,0,.86.86H24.64a.86.86,0,0,0,0-1.72H10.86A.86.86,0,0,0,10,47.65Z"
      />
      <path
        id="Trazado_71016"
        data-name="Trazado 71016"
        d="M75.36,44.48a.947.947,0,0,0,.43-.11l11.06-6.35a.865.865,0,0,0,.32-1.18.874.874,0,0,0-1.18-.32L74.93,42.87a.865.865,0,0,0-.32,1.18A.877.877,0,0,0,75.36,44.48Z"
      />
      <path
        id="Trazado_71017"
        data-name="Trazado 71017"
        d="M85.99,58.78a.836.836,0,0,0,.43.11.856.856,0,0,0,.75-.43.868.868,0,0,0-.32-1.18L75.79,50.93a.865.865,0,0,0-.86,1.5Z"
      />
      <path
        id="Trazado_71018"
        data-name="Trazado 71018"
        d="M89.14,46.79H75.36a.86.86,0,1,0,0,1.72H89.14a.86.86,0,1,0,0-1.72Z"
      />
      <path
        id="Trazado_71019"
        data-name="Trazado 71019"
        d="M47.9,55.31v3.27h4.19V55.31H50.64v1.52a.64.64,0,0,1-.64.65.649.649,0,0,1-.65-.65V55.31Z"
      />
      <path
        id="Trazado_71020"
        data-name="Trazado 71020"
        d="M64.46,47,69.3,32.7a3.791,3.791,0,0,0-3.6-5.02H34.29a3.814,3.814,0,0,0-3.6,5.02L35.53,47ZM32.96,30.53a1.626,1.626,0,0,1,1.33-.69H65.7a1.631,1.631,0,0,1,1.34.69,1.592,1.592,0,0,1,.22,1.48L63.06,44.4a.627.627,0,0,1-.6.45H37.55a.651.651,0,0,1-.62-.45L32.74,32.01A1.621,1.621,0,0,1,32.96,30.53Z"
      />
      <path
        id="Trazado_71021"
        data-name="Trazado 71021"
        d="M70.35,54.01l-5.68-5.72H35.34l-5.69,5.72Z"
      />
      <path
        id="Trazado_71022"
        data-name="Trazado 71022"
        d="M32.01,69.33a3.759,3.759,0,0,0,3.7,2.98H64.28a3.767,3.767,0,0,0,3.7-2.98l3.1-14.03H53.38v3.93a.651.651,0,0,1-.64.64H47.26a.647.647,0,0,1-.65-.64V55.3H28.9ZM31.5,56.87a.655.655,0,0,1,.78.48L34.8,68.7a.942.942,0,0,0,.93.76H64.3a.95.95,0,0,0,.93-.76l2.52-11.35a.651.651,0,0,1,.78-.48.621.621,0,0,1,.48.76L66.49,69a2.237,2.237,0,0,1-2.19,1.76H35.72A2.257,2.257,0,0,1,33.51,69L31,57.63A.646.646,0,0,1,31.5,56.87Z"
      />
      <path
        id="Trazado_71023"
        data-name="Trazado 71023"
        d="M62,43.56,66.05,31.6a.368.368,0,0,0-.34-.47H34.29a.348.348,0,0,0-.28.14.357.357,0,0,0-.05.33l4.05,11.96Z"
      />
    </g>
  </svg>
);
export default TreasureButton;
