import React, { useEffect } from 'react';

const EggMint = () => {
    const URL = "https://form.jotform.com/240328042642145"
    useEffect(() => {
        localStorage.removeItem('unauthorizedPath');
    })
    return (
        <div className="black-bg pt-5">
            <div className="section">
                <div className="container">
                    <div className="row">
                        <iframe
                            id="JotFormIFrame-240328042642145"
                            title="Prizes for One Tap"
                            onload="window.parent.scrollTo(0,0)"
                            allowtransparency={true}
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src={URL}
                            frameborder="0"
                            style={{
                                minWidth: "100%",
                                maxWidth: "100%",
                                height: 700,
                                border: "none",
                            }}
                            scrolling="no"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EggMint;
