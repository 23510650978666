import React, { useState } from 'react';
import { globalRank } from '../utils/Global';
import RankLeaderboard from '../components/RankLeaderboard';

function LeaderboardPage() {
    const [activeLeaderboard, setActiveLeaderboard] = useState('Global Rank');

    const handleNavigation = (leaderboard) => {
        setActiveLeaderboard(leaderboard);
    };

    return (
        <div className="leaderboard-page">
            <div className="leaderboard-sidebar">
                <button
                    className={`rank-button-global ${activeLeaderboard === 'global' ? 'active' : ''}`}
                    onClick={() => handleNavigation('Global Rank')}>
                    Earning Ranking
                </button>
                {Object.keys(globalRank).map((rank, index) => {
                    const rankClass = `rank-button-${rank.split(' ')[0].toLowerCase()}`;
                    return (
                        <button
                            key={index}
                            className={`${rankClass} ${activeLeaderboard === rank ? 'active' : ''}`}
                            onClick={() => handleNavigation(rank)}>
                            {rank}
                        </button>
                    );
                })}
            </div>
            <div className="leaderboard-content">
                <RankLeaderboard rank={activeLeaderboard} />
            </div>
        </div>
    );
}

export default LeaderboardPage;
