import * as React from "react";
const Forge = (props) => (
  <svg
    width={118}
    height={44}
    viewBox="0 0 118 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.697 0H0V44L7.91876 36.3478V7.82609H36.9542L44.697 0Z"
      fill="white"
    />
    <path d="M14.6057 14.4348H29.9153L14.6057 29.5652V14.4348Z" fill="white" />
    <path
      d="M45.4939 14.4348V29.7391H21.6807L25.8221 25.913H41.762V18.2609H33.2319L37.3733 14.4348H45.4939Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.6265 29.5652V14.4348H69.6626V23.8261H66.4638L69.6626 29.6522H65.3976L62.1988 23.8261H51.3584V29.5652H47.6265ZM65.9307 18.087V20.1739H51.3584V18.087H65.9307Z"
      fill="white"
    />
    <path
      d="M75.5271 18.087V26.087H90.0994V23.8261H77.4819V20.1739H93.8313V29.7391H71.7952V14.4348H93.8313V18.087H75.5271Z"
      fill="white"
    />
    <path d="M95.6084 18.087V14.4348H118V18.087H95.6084Z" fill="white" />
    <path d="M95.6084 23.8261V20.1739H118V23.8261H95.6084Z" fill="white" />
    <path d="M95.6084 29.5652V25.913H118V29.5652H95.6084Z" fill="white" />
  </svg>
);
export default Forge;
