/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import * as animationData from "../assets/lotties/Dot_Loading.json";
import Lottie from "react-lottie";
import { Button } from "../components/Button";
import { ERC721TokenType, Link } from "@imtbl/imx-sdk";
import { environment, linkAddress, Toast, Sphereone, Ethereum, ImmutableX } from "../utils/Global";
import { assetsPlaceholder } from "../utils/AssetsPlaceholder";
import defaultImage from "../assets/img/PlaceholderNft.png";
import { Context } from "../context/WalletContext";
import Popup from "../components/Popup";
import Wallet from "../assets/svg/Wallet";
import TreasureButton from "../assets/svg/treasureButton"
import Close from "../assets/svg/Close";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";
import { useNavigate } from 'react-router-dom';
import ErrorShowing from "../components/ErrorShowing";
import PinInstrucionsOverlay from "../components/PinInstructionsOverlay";
import qorbi_coin from '../assets/img/qorbi-coin.png';

const immutableService = require('../context/ImmutableService');
const saakuruService = require('../context/SaakuruService');
const qorbiService = require('../context/QorbiService');

const MyAssets = () => {

  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [openLootAssets, setOpenLootAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { sphereOneWallets, devicedUsed, qorbiUserData } = useSphereOneProvider()
  const [pinOverlay, setPinOverlay] = useState(true);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [showLootboxButton, setShowLootboxButton] = useState(false)
  const [showKitchen, setShowKitchen] = useState(false)
  const [activeTab, setActiveTab] = useState('IMX'); // IMX - Open Loot
  const ownerAddress = sphereOneWallets?.find((wallet) => !wallet.isImported);

  const toggleSwitch = () => {
    setActiveTab(activeTab === "IMX" ? "Open Loot" : "IMX");
    setSelected([])
  };

  const handleLootBoxes = (skinAssets) => {//This part filters the lootboxes to know if the user has any, if he does, he will see a button to open it
    let lootBoxes = skinAssets.filter((skin) =>
      ["0x8a2315e441786e10ff1962387407628108b9ba33", "0x624ded48d23337787212f975157dc477e20b1334"].includes(skin.token_address) &&
      skin?.name?.startsWith("One Tap Loot Box")
    );
    if (state?.user?.authType === Sphereone) {
      lootBoxes = lootBoxes.filter((skin) => ["imx"].includes(skin.status));
    }
    if (lootBoxes.length > 0) {
      setShowLootboxButton(true);
      const lootBoxesJSON = JSON.stringify(lootBoxes);
      localStorage.setItem('lootboxes', lootBoxesJSON);
    }
  }
  const link = new Link(linkAddress);
  const { validateEggTrade, transferJaguar, state } = useContext(Context);
  const sendPetForTrade = async (eggsList) => {
    // use api to transfer jaguar add delay to make sure the transaction finished
    const send = async () => {
      const addressToSendPet = state.userWallet.string;
      let res2 = await transferJaguar(eggsList, addressToSendPet);
      if (res2.success) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        Toast.set({
          show: true,
          desc: "Congratulations! Your pet has been transferred to your wallet.",
          type: "success",
        });
      }
      return res2.success;
    };
    setTimeout(async () => {
      await new Promise(resolve => setTimeout(resolve, 3000));
      let time1 = await send();
      if (!time1) {
        let time2 = await send();
        if (!time2) {
          let time3 = await send();
          if (!time3) {
            Toast.set({
              show: true,
              desc: "Something went wrong. Please contact us at info@qorgaming.com ",
              type: "error",
            });
          }
        }
      }
    }, 5000);
  }

  const verify = async () => {
    setShowPopup(false);
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(assets[element].token_id);
      nfts.push({
        type: ERC721TokenType.ERC721, // Must be of type ERC721
        tokenId: assets[element].token_id, // the token ID, SphereOne and IMX give different name to that field
        tokenAddress: assets[element].token_address, // the collection address / contract address this token belongs to
        toAddress:
          environment === "dev"
            ? "0x5B7Fc9dECd64aA393E9234a6D8cd92391199cb79"
            : "0x0C42E145B9dc6396928f8b3f6731FCC4c5D7A176", // the wallet address this token is being transferred to
      });
    });
    // validate eggs in the api
    const arrayOfArraysOfNumbers = array.map(str => [parseInt(str, 10)]);//The api changed, this will do the trick
    let res = await validateEggTrade(arrayOfArraysOfNumbers);
    console.log("res: ", res);
    if (res.success) {
      // if (true) { // This is for testing
      let response = await link.batchNftTransfer(nfts);
      // transfer token to wallet admin
      if (response.result.length > 0) {
        sendPetForTrade(arrayOfArraysOfNumbers)
      }
    }
  };
  const [showWidget, setShowWidget] = useState(false);
  const handleWalletClick = () => {
    setShowWidget(!showWidget);
  };

  const [activeQor, setActiveQor] = useState(null);
  const [activeOLQor, setActiveOLQor] = useState(null);
  const getActiveQor = async (qorHolder) => {
    try {
      const { data } = await axios.get(`https://api.qorbiworld.com/stake/get-only-qor-x-active/${qorHolder}`);
      if (data?.qorXPass?.qorXNFTID) {
        setActiveQor(data.qorXPass.qorXNFTID)
      }

    } catch (error) {
      console.error('No Active Qor:', error);
    }
  }
  const getActiveOLQor = async (qorHolder) => {
    try {
      if (qorbiUserData?.openLootInfo?.id) {
        const { data } = await axios.get(`https://api.qorbiworld.com/stake/get-only-qor-x-active/${qorHolder}`);
        if (data?.qorXPass?.qorXNFTID) {
          setActiveOLQor(data.qorXPass.qorXNFTID)
        }
      }

    } catch (error) {
      console.error('No Active Qor:', error);
    }
  }
  useEffect(() => {
    const fetchOpenLootAssets = async () => {
      if (qorbiUserData?.openLootInfo?.id) {
        try {
          const openLootAssets = await qorbiService.getOpenLootAssets(qorbiUserData.openLootInfo.id);
          // console.log("Open Loot Assets:", openLootAssets[2]);
          const sortedAssets = openLootAssets.sort((a, b) => {
            const order = {
              "Qorb": 1,
              "Qor-X Lifetime Pass": 2,
              "Qor-X Seasonal Pass": 3,
              "Qorb Premier Badge": 4,
            };

            const orderA = order[a.metadata.name] || 5;
            const orderB = order[b.metadata.name] || 5;

            return orderA - orderB;
          });
          getActiveOLQor(qorbiUserData.openLootInfo.id)
          setOpenLootAssets(sortedAssets);
        } catch (error) {
          console.error("Error fetching Open Loot Assets:", error);
        }
      } else {
        console.log("No Open Loot Info available for this user.");
      }
    };
    const allCollections = [
      "0xeea2fe42cc1774a802edfc7a08a35c361827befc",
      "0x8a2315e441786e10ff1962387407628108b9ba33",
      "0x6cc712a73cb45d212f594ef942385ef90141dac0",
      "0x938b5c1850106ebc82d49af65a030e1d9083f2f5",
      "0x2d59c7b8d253b826cdba9389be397a68af88cf08"
    ];

    localStorage.removeItem('unauthorizedPath');

    const updateAssets = (newAssets) => {
      setAssets(prevAssets => {
        const combinedAssets = [
          ...prevAssets,
          ...newAssets.filter(
            newAsset => !prevAssets.some(prevAsset => prevAsset.token_id === newAsset.token_id && prevAsset.token_address === newAsset.token_address)
          )
        ];
        handleLootBoxes(combinedAssets);
        const eggAssets = combinedAssets.filter(item => item.metadata?.class === "Pet Egg");
        setShowKitchen(eggAssets.length > 0);
        return combinedAssets;
      });
    };

    const fetchAllAssets = async (walletAddress) => {
      await immutableService.getAssetsFromCollections(walletAddress, allCollections, updateAssets);
      await saakuruService.getAssetsFromCollections(walletAddress, updateAssets);
    };
    if (assets.length === 0) {
      if (state?.user?.authType) {
        fetchOpenLootAssets()
        getActiveQor(state.user.data);
      }
      const addressLocalStorage = state?.userWallet?.string;
      if (addressLocalStorage !== undefined && addressLocalStorage !== null) {
        fetchAllAssets(state.user.data).then(() => setLoading(false));
      } else {
        setAssets(assetsPlaceholder);
        setShowConnectMessage(true);
      }
      setLoading(false);
    }
  }, [state]);

  const loadingOpt = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const transferItemsToAddress = async (destinationAddress) => {
    setShowTransferPopup(false);
    const successfulTransfers = []
    const array = [];
    const nfts = [];
    selected.forEach((element) => {
      array.push(assets[element].token_id);
      nfts.push({
        type: ERC721TokenType.ERC721,
        tokenId: assets[element].token_id,
        tokenAddress: assets[element].token_address,
        toAddress: destinationAddress,
      });
    });
    if (state?.user?.authType === Ethereum || state?.user?.authType === ImmutableX) {
      let response = await link.batchNftTransfer(nfts);
      if (response.result.length > 0) {
        Toast.set({
          show: true,
          desc: "Congratulations! Your assets have been transfered.",
          type: "success",
        });
      }
    }
    if (successfulTransfers.length === nfts.length) {
      Toast.set({
        show: true,
        desc: "Congratulations! Your assets have been transfered.",
        type: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }

  const handlePinPopup = async (transaction) => {
    switch (transaction) {
      case 'trade':
        setShowPopup(true)
        break;
      case 'transfer':
        setShowTransferPopup(true)
        break;
      default:
        console.error('Unknown option');
    }
  };
  const openDetails = (adrs, token_id, status) => {
    console.log(adrs);
    const itemId = token_id;
    if (status === 'imx') {
      const collection =
        adrs === "0xeea2fe42cc1774a802edfc7a08a35c361827befc" ? "egg" :
          adrs === "0x8a2315e441786e10ff1962387407628108b9ba33" ? "skin" :
            adrs === "0x938b5c1850106ebc82d49af65a030e1d9083f2f5" ? "badge" :
              adrs === "0x6cc712a73cb45d212f594ef942385ef90141dac0" ? "X" :
                adrs === "0x2d59c7b8d253b826cdba9389be397a68af88cf08" ? "qorb" :
                  "unknown";
      if (collection !== "unknown") {
        window.open(`/nft-details/${collection}/${itemId}`, '_blank')
      }
    } else if (status === 'saakuru') {
      window.open(`https://saakuru.com/explorer/token/${adrs}/instance/${token_id}/token-transfers`, '_blank')
    }
  }

  const activateQorX = async (token_id) => {
    const idPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    const isMatchingPattern = idPattern.test(token_id);
    if (isMatchingPattern) {
      try {
        let usernameQor = "QorPassUser";
        const apiUrl = 'https://api.qorbiworld.com/stake/stake-nft';
        const requestBody = {
          "username": usernameQor,
          "email": "qorpass@qor.com",
          "walletAddress": qorbiUserData.openLootInfo.id,
          "QorXNftID": token_id
        };
        axios.post(apiUrl, requestBody)
          .then(response => {
            setErrorMsg("Qor-X Pass Activated");
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch(error => {
            setErrorMsg("QORX not activated. Try again, or contact us in Discord");
          });
      } catch (error) {
        setErrorMsg(error);
      }
    } else {
      try {
        let usernameQor = "QorPassUser";
        const apiUrl = 'https://api.qorbiworld.com/stake/stake-nft';
        const requestBody = {
          "username": usernameQor,
          "email": "qorpass@qor.com",
          "walletAddress": ownerAddress?.address || state.user.data,
          "QorXNftID": token_id
        };
        axios.post(apiUrl, requestBody)
          .then(response => {
            setErrorMsg("Qor-X Pass Activated");
          })
          .catch(error => {
            setErrorMsg("QORX not activated. Try again, or contact us in Discord");
          });
      } catch (error) {
        setErrorMsg(error);
      }
    }
  };
  return (
    <>
      {pinOverlay && state?.user?.authType === Sphereone ?
        <PinInstrucionsOverlay closeInstructions={() => setPinOverlay(false)}></PinInstrucionsOverlay>
        :
        <div className="darkbg pt-5 assets">
          {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
          {loading && !pinOverlay &&
            <div className="loading-container">
              <Lottie options={loadingOpt} height={90} width={88} />
              {state.loadingText ? (
                <div className="loading-text"></div>
              ) : null}
            </div>
          }
          <div
            style={{
              position: "fixed",
              right: "20px",
              bottom: "20px",
              display: "flex",
              alignItems: "flex-end",
              zIndex: 9999,
            }}
          >
            {errorMsg && <ErrorShowing errorText={errorMsg} />}
            {errorMsg &&
              <div style={{
                width: "50px",
                height: "50px",
                backgroundColor: "green",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginLeft: "10px",
              }}
                onClick={() => {
                  setErrorMsg(null)
                }}
              >
                <Close />
              </div>
            }
            {activeQor !== null && <div style={{
              width: "50px",
              height: "50px",

              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              marginLeft: "10px",
            }}
              onClick={() => {
                navigate('/qorx');
              }}
            >
              <img
                src={qorbi_coin}
                alt="Qorbi_Coin"
                style={{
                  width: 'auto',
                  height: '50px',
                }}
              />
            </div>}
            {state?.user?.authType === Sphereone && <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "green",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onClick={handleWalletClick}
            >
              {showWidget ? <Close /> : <Wallet />}
            </div>}
            {showLootboxButton && <div
              style={{ width: "50px", height: "50px", filter: "drop-shadow(0 0 10px rgba(0, 255, 64, 0.8))", background: "green", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", marginLeft: "10px", }}
              onClick={() => navigate('/openlootbox')}
            >
              <TreasureButton></TreasureButton>
            </div>}
          </div>

          {showPopup ? (
            <Popup
              onClose={() => setShowPopup(false)}
              title="Confirmation required"
              content={
                <>
                  <p>
                    Are you sure you want to trade your eggs for a pet? You cannot
                    reverse this transaction.
                  </p>
                  <Button label="Yes, Please" onClick={() => verify()} />
                  <span style={{ width: 10, display: "inline-block" }} />
                  <Button
                    transparent
                    label="No"
                    onClick={() => setShowPopup(false)}
                  />
                </>
              }
            />
          ) : null}
          {showTransferPopup ? (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999, }}>
              <div style={{ background: 'black', padding: '20px', borderRadius: '10px', textAlign: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', }} >
                <p style={{ fontSize: '24px', color: 'white' }}>Confirmation Required</p>
                <p style={{ fontSize: '16px', color: 'grey' }}>Are you sure you want to transfer your assets? If so, please input the recipient's address.</p>
                <input
                  style={{
                    width: '100%',
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '5px',
                  }}
                  type="text"
                  placeholder="Recipient's Address"
                  value={destinationAddress}
                  onChange={(e) => setDestinationAddress(e.target.value)}
                />
                <Button label="Yes, Please" onClick={() => {
                  if (destinationAddress.trim() !== '') {
                    transferItemsToAddress(destinationAddress);
                  } else {
                    alert('Please enter the recipient\'s address.');
                  }
                }}
                />
                <span style={{ width: 10, display: "inline-block" }} />
                <Button transparent label="No" onClick={() => setShowTransferPopup(false)} />
              </div>
            </div>
          ) : null}
          <div style={{ position: "fixed", top: 90, right: 20, display: "flex", flexDirection: "column", zIndex: 9 }}>
            {showKitchen && (
              <button className="floatingassets-btn mb-2" onClick={() => navigate('/kitchen')}>
                Burn Eggs
              </button>
            )}
            {selected.length > 0 && selected.every(asset => assets[asset].token_address === "0xeea2fe42cc1774a802edfc7a08a35c361827befc") && (
              <button className="floatingassets-btn mb-2" onClick={() => handlePinPopup("trade")}>
                Trade For Pet
              </button>
            )}
            {(selected.length > 0 && (state?.user?.authType === Sphereone || state?.user?.authType === Ethereum || state?.user?.authType === ImmutableX)) &&
              <button className="floatingassets-btn mb-2" onClick={() => handlePinPopup("transfer")}>
                Transfer Assets
              </button>}

          </div>
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 right-side">
                  <div>
                    <h2>Your {activeTab} Assets</h2>
                    <div className="switch-container">
                      <span style={{ color: "#ffffff" }}>IMX</span>
                      <div
                        className={`switch ${activeTab === "IMX" ? "imx" : "open-loot"}`}
                        onClick={toggleSwitch}
                      >
                        <div className="switch-slider"></div>
                      </div>
                      <span style={{ color: "#ffffff" }}>Open Loot</span>
                    </div>
                  </div>
                  <div className="row">
                    {(activeTab === "IMX" ? assets : openLootAssets).length > 0 ? (
                      (activeTab === "IMX" ? assets : openLootAssets).map((item, index) => (
                        <div
                          className="col-md-3 mb-3"
                          key={index}
                          onClick={() => {
                            if (activeTab !== 'Open Loot') {
                              // Check if the egg is already selected
                              if (selected.includes(index)) {
                                // Remove from the array
                                const indx = selected.indexOf(index);
                                const clone = [...selected];
                                clone.splice(indx, 1);
                                setSelected(clone);
                              } else {
                                // Add to the array
                                setSelected([...selected, index]);
                              }
                            }
                          }}
                        >
                          <div
                            className="bg"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              backgroundImage: `url(${defaultImage})`,
                              overflow: 'hidden'
                            }}
                          >
                            <img
                              className="tokenImage"
                              src={item.image_url || (item?.metadata?.name === "Qorb" ? item.extra?.imageUrl : item.metadata?.imageUrl) || defaultImage}
                              style={{
                                width: devicedUsed === "computer" ? "100%" : undefined,
                                objectFit: item?.name?.startsWith("One Tap Loot Box") ? "contain" : undefined
                              }}
                              alt={item?.name || item?.metadata?.name}
                            />
                            {(state?.user?.authType && item.token_address === "0x6cc712a73cb45d212f594ef942385ef90141dac0") ? (
                              activeQor !== null ? (
                                (activeQor === item.token_id || activeQor === item?.id) ? (
                                  <button
                                    className="qorx-progress"
                                    style={{ width: 'max-content' }}
                                    onClick={() => navigate('/qorx')}
                                  >
                                    Pledging
                                  </button>
                                ) : null
                              ) : (
                                <button
                                  className="qorx-progress"
                                  style={{ width: 'max-content' }}
                                  onClick={() => activateQorX(item?.token_id || item?.id)}
                                >
                                  Pledge Pass
                                </button>
                              )
                            ) :
                              (state?.user?.authType && (item?.metadata?.name === "Qor-X Seasonal Pass" || item?.metadata?.name === "Qor-X Lifetime Pass")) ?
                                (
                                  activeOLQor !== null ? (
                                    (activeOLQor === item.token_id || activeOLQor === item?.id) ? (
                                      <button
                                        className="qorx-progress"
                                        style={{ width: 'max-content' }}
                                        onClick={() => navigate('/qorx-ol')}
                                      >
                                        Pledging
                                      </button>
                                    ) : null
                                  ) : (
                                    <button
                                      className="qorx-progress"
                                      style={{ width: 'max-content' }}
                                      onClick={() => activateQorX(item.token_id || item?.id)}
                                    >
                                      Pledge Pass
                                    </button>
                                  )
                                ) :
                                null
                            }
                            {item?.isAble === true && (
                              <button
                                className="qorx-progress qorb-badge"
                                style={{ width: 'max-content' }}
                                onClick={() => navigate(`/qorshop?badge=${item.token_id || item?.id}&wallet=${item?.user || item?.userId}`)}
                              >
                                Mint Badge
                              </button>
                            )}
                            {(item?.token_address === '0x2d59c7b8d253b826cdba9389be397a68af88cf08' || item?.metadata?.name === 'Qorb') && (
                              <button
                                className="qorx-progress qorb-staking"
                                style={{ width: 'max-content' }}
                                onClick={() => navigate(`/qorb-staking?qorb=${item.token_id || item?.id}&wallet=${item?.user || item?.userId}`)}
                              >
                                Qorb Pledging
                              </button>
                            )}
                            {selected.includes(index) && (
                              <div className="check">
                                <span className="fa fa-check-square" />
                              </div>
                            )}
                          </div>
                          <div className="cont">
                            <div className="logo" onClick={() => openDetails(item.token_address, item.token_id, item.status)}>
                              <img alt="logo" src="/assets/img/Green_Isotype.png" />
                            </div>
                            <h4>{item.name || (item?.metadata?.name === "Qorb" ? item.extra?.attributes[0]?.value : item.metadata?.name)} {item.issuedId && `#${item.issuedId}`}</h4>
                            {activeTab === "Open Loot" && (
                              <div>
                                {item.status === "unlocked" && "Marketplace Item"}
                                {item.status === "locked" && "Active Listing"}
                                {item.status === "in-game" && "In-Game Item"}
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <h2 className="text-center">
                        There are no assets for {activeTab === "IMX" ? "Qorbi World" : "Open Loot"} in your wallet
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      }
    </>
  );
};

export default MyAssets;
