import React from 'react';

export const NewCard = ({ image, title, content, game, timeAgo, link }) => {
    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        cursor: 'pointer',
        margin: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #fff',
    };

    const imageStyle = {
        height: '200px',
        backgroundSize: 'cover',
    };

    const titleStyle = {
        margin: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
    };

    const contentStyle = {
        margin: '10px',
        color: '#555',
    };

    const infoContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
    };

    const gameNameStyle = {
        fontSize: '14px',
        marginRight: '10px',
        color: '#fff'
    };

    const timeAgoStyle = {
        fontSize: '14px',
        color: '#fff',
    };

    return (
        <div style={cardStyle} onClick={() => window.open(link, '_blank')}>
            <div style={{ ...imageStyle, backgroundImage: `url(${image})` }}></div>
            <div style={titleStyle}>
                {title}
            </div>
            <div style={contentStyle}>
                {content}
            </div>
            <div style={infoContainerStyle}>
                <div style={gameNameStyle}>
                    {game.name}
                </div>
                <div style={timeAgoStyle}>
                    {timeAgo}
                </div>
            </div>
        </div>
    );
};